/* eslint-disable react/prop-types */
import './Searchbar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export default function Searchbar({ onChange, onSubmit, placeholder }) {
  return (
    <div className="xpektor-searchbar">
      <form onSubmit={onSubmit} className="search-form">
        <label htmlFor="search">Search</label>
        <FontAwesomeIcon className="input-icon" icon={faSearch} />
        <input
          id="search"
          placeholder={placeholder}
          onChange={(e) => onChange(e)}
        />
      </form>
    </div>
  );
}
