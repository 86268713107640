import { useEffect, useState } from 'react';
import AddUserPopup from './CreateUser';
import UserService from '../../../../Services/UserService';
import CustomerService from '../../../../Services/CustomerService';
import './UserSettings.scss';
import UserClientAccessService from '../../../../Services/UserClientAccessService';
import UpdateUser from './UpdateUser';
import UserTable from './UserTable/UserTable';
import XpektorButton from '../../../../Components/XpektorButton/XpektorButton';
import DeleteUserModal from '../ClientSettings/Modals/DeleteUserModal';

export default function UserSettings() {
  const [showAddUser, setShowAddUser] = useState(false);
  const [users, setUsers] = useState([]);
  const [userToEdit, setUserToEdit] = useState({});
  const [userToDelete, setUserToDelete] = useState({});
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [currentClientAccesses, setCurrentClientAccesses] = useState([]);
  const [deletionText, setDeletionText] = useState({});
  const [deleteOwnAccount, setDeleteOwnAccount] = useState({});
  const handleCloseAddUser = () => {
    setUserToEdit({});
    setCurrentClientAccesses([]);
    setShowAddUser(false);
  };
  const handleShowAddUser = () => setShowAddUser(true);

  const [order, setOrder] = useState('asc');

  const sortByName = () => {
    if (order === 'asc') {
      users.sort((a, b) => {
        const nameA = a.name?.toUpperCase();
        const nameB = b.name?.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setOrder('desc');
    } else if (order === 'desc') {
      users.sort((a, b) => {
        const nameA = a.name?.toUpperCase();
        const nameB = b.name?.toUpperCase();

        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      setOrder('asc');
    }
  };

  useEffect(() => {
    const getData = async () => {
      const user = await UserService.getCurrentUser();
      setCurrentUser(user);
      await CustomerService.getUsers(user.customerId)
        .then((response) => {
          setUsers(response.data);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            alert('Hittar inga klienter');
          }
          if (error.response.status === 500) {
            alert('Något gick fel försök igen om en stund');
          }
        });
    };
    getData();
  }, []);

  const getCurrentClientAccess = (user) => {
    UserClientAccessService.GetUserClientAccessOnUserId(user.id)
      .then((response) => {
        const temp = response.data;
        temp.map((item) => {
          return setCurrentClientAccesses((prevState) => [
            ...prevState,
            item.clientId,
          ]);
        });
      })
      .catch((error) => {
        if (error.status === 404) {
          setCurrentClientAccesses([]);
        }
      });
  };

  const EditUser = () => {
    handleShowAddUser();
  };

  const CheckUserToDelete = (data) => {
    if (currentUser.id === data.id) {
      setDeleteOwnAccount(true);
      setDeletionText(
        'OBS! Du kommer att radera ditt konto på Xpektor. Denna åtgärd kan inte ångras, du kommer att bli utloggad direkt.'
      );
    } else {
      setDeleteOwnAccount(false);
      setDeletionText(
        `Användare ${data.name} kommer att raderas. Denna åtgärd kan inte ångras.`
      );
    }
  };

  return (
    <div className="user-settings-container">
      <div className="user-settings-header">
        <XpektorButton
          content="Lägg till användare"
          onClick={() => {
            handleShowAddUser();
          }}
        />
      </div>
      <UserTable sortByName={sortByName} order={order}>
        {users.map((data) => {
          return (
            <UserTable.Item
              onEditClick={() => {
                setUserToEdit(data);
                getCurrentClientAccess(data);
                EditUser();
              }}
              onDeleteClick={() => {
                setUserToDelete(data);
                CheckUserToDelete(data);
                getCurrentClientAccess(data);
                setShowDeleteUserModal(true);
              }}
              user={data}
              key={data.id}
            />
          );
        })}
        {showDeleteUserModal && (
          <DeleteUserModal
            show={showDeleteUserModal}
            handleClose={() => setShowDeleteUserModal(false)}
            userToDelete={userToDelete}
            deletionText={deletionText}
            deleteOwnAccount={deleteOwnAccount}
          />
        )}
      </UserTable>

      {showAddUser &&
        (Object.keys(userToEdit).length > 0 ? (
          <UpdateUser
            show={showAddUser}
            handleClose={handleCloseAddUser}
            userToEdit={userToEdit}
            userClientAccesses={currentClientAccesses}
            className="border-0"
          />
        ) : (
          <AddUserPopup
            show={showAddUser}
            handleClose={handleCloseAddUser}
            className="border-0"
          />
        ))}
    </div>
  );
}
