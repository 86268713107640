/* eslint-disable react/prop-types */
import './CardButton.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function CardButton({
  onClick,
  icon,
  disabled,
  styleClass,
  image,
  imageAlt,
  textHeader,
  textContent,
}) {
  return (
    <div className="card-button">
      <button
        className={styleClass}
        type="button"
        onClick={onClick}
        disabled={disabled}
      >
        {image && <img className="partner-logo" src={image} alt={imageAlt} />}
        <span className="text-container">
          <p className="text-header">{textHeader}</p>
          <p className="text-content">{textContent}</p>
        </span>
        {!disabled ? <FontAwesomeIcon className="icon" icon={icon} /> : ''}
      </button>
    </div>
  );
}
