/* eslint-disable react/prop-types */
import './XpektorSlider.scss';
import React from 'react';

export default function XpektorSlider({ children, activeSlideIndex }) {
  const width = React.Children.count(children) * 100;
  const widthStyle = {
    width: `${width}%`,
    left: `${width / 2 - 50 - 100 * activeSlideIndex}%`,
  };

  return (
    <div className="xpektor-slider-container" style={widthStyle}>
      {children}
    </div>
  );
}

XpektorSlider.Slide = function Slide({ children, hidden }) {
  return (
    <div className={`slide-container ${hidden ? 'slide-hidden' : ''}`}>
      {children}
    </div>
  );
};
