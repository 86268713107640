import './ServiceStatusSettings.scss';
import { useState, useEffect } from 'react';
import StatusService from '../../../../Services/StatusService';
import ServiceStatusTable from '../ServiceStatusTable/ServiceStatusTable';
import ServiceStatusMessageModal from '../ServiceStatusMessageModal';

export default function ServiceStatusSettings() {
  const [serviceStatusMessages, setServiceStatusMessages] = useState([]);
  const [showCreateMessageModal, setShowCreateMessageModal] = useState(false);

  const reloadMessages = async () => {
    await StatusService.getServiceStatusMessages()
      .then((response) => {
        setServiceStatusMessages(response.data);
      })
      .catch(() => {
        setServiceStatusMessages([]);
      });
  };

  const deleteMessage = async (id) => {
    await StatusService.deleteServiceStatusMessage(id)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        reloadMessages();
      });
  };
  useEffect(() => {
    const getMessages = async () => {
      await StatusService.getServiceStatusMessages()
        .then((response) => {
          setServiceStatusMessages(response.data);
        })
        .catch(() => {});
    };

    getMessages();
  }, []);

  return (
    <div className="service-status-settings-container">
      <ServiceStatusTable onAddClick={() => setShowCreateMessageModal(true)}>
        {serviceStatusMessages.map((message) => {
          return (
            <ServiceStatusTable.Item
              key={message.id}
              message={message}
              onDeleteClick={(e) => deleteMessage(e)}
            />
          );
        })}
      </ServiceStatusTable>
      {showCreateMessageModal && (
        <ServiceStatusMessageModal
          handleClose={() => setShowCreateMessageModal(false)}
          onCreateMessage={() => reloadMessages()}
        />
      )}
    </div>
  );
}
