/* eslint-disable no-alert */
import { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  faEyeSlash,
  faEnvelope,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import './Login.scss';
import XpektorForm from '../../../Components/XpektorForm/XpektorForm';
import XpektorLogo from '../../../Images/Xpektor.png';
import AuthContext from '../../../context/auth-context';
import AuthService from '../../../Services/AuthService';
import UserService from '../../../Services/UserService';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorText, setErrorText] = useState('');
  const [passwordFieldType, setPasswordFieldType] = useState('password');
  const [loggingIn, setLoggingIn] = useState(false);

  const [sendingForgot, setSendingForgot] = useState(false);
  const [forgotRequestSent, setForgotRequestSent] = useState(false);

  const [action, setAction] = useState('login');

  const history = useHistory();
  const [, setAuthState] = useContext(AuthContext);

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    setAuthState((state) => ({ ...state, user }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const togglePasswordVisibility = () => {
    if (passwordFieldType === 'password') {
      setPasswordFieldType('text');
    } else {
      setPasswordFieldType('password');
    }
  };

  const loginAPI = async () => {
    try {
      setLoggingIn(true);
      const response = await AuthService.login(email, password);
      if (response) {
        setAuthState((state) => ({ ...state, user: response }));
        history.push('/dashboard');
      }
    } catch (error) {
      setLoggingIn(false);
      if (error.response.status === 400) {
        setErrorText('Fel användarnamn eller lösenord.');
      } else if (error.response.status === 500) {
        setErrorText('Något gick fel försök igen om en stund');
      }
    }
  };
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const handleLoginSubmit = async (event) => {
    event.preventDefault();

    if (!emailRegex.test(email)) {
      setErrorText('Fel användarnamn eller lösenord.');
      return;
    }

    try {
      localStorage.clear();
      await loginAPI();
    } catch (e) {
      alert(e.message);
    }
  };

  const handleForgotSubmit = async (event) => {
    event.preventDefault();

    if (!emailRegex.test(email)) {
      setErrorText('Ej giltig adress');
      return;
    }

    setSendingForgot(true);
    await UserService.ForgotPassword(email)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        setForgotRequestSent(true);
        setErrorText('');
        setSendingForgot(false);
      });
  };

  function validateLoginForm() {
    return email.length > 0 && password.length > 0;
  }

  function validateForgotForm() {
    return email.length > 0;
  }

  return (
    <div className="login-wrapper">
      <div className="left-bg">
        <div className="left-content-container">
          <div className="left-header">
            <div className="logo">
              <img src={XpektorLogo} alt="XpektorLogo" />
              <a href="/">Xpektor</a>
            </div>
            <div className="login-title">
              {action === 'login' ? (
                <p className="login-action-title">Logga in</p>
              ) : (
                <p className="forgot-action-title">Begär nytt lösenord</p>
              )}
            </div>
          </div>
          <div className="login-form-container">
            {action === 'login' ? (
              <XpektorForm onSubmit={handleLoginSubmit}>
                <XpektorForm.Input
                  label="Email"
                  type="text"
                  placeholder="john-smith@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  icon={faEnvelope}
                />
                <XpektorForm.Input
                  label="Lösenord"
                  type={passwordFieldType}
                  placeholder="Skriv lösenord"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  icon={passwordFieldType === 'password' ? faEyeSlash : faEye}
                  onIconClick={() => {
                    togglePasswordVisibility();
                  }}
                  infoText={errorText}
                />
                <XpektorForm.Button
                  text={loggingIn ? 'Loggar in...' : 'Logga in'}
                  disabled={!validateLoginForm() || loggingIn}
                />
              </XpektorForm>
            ) : (
              <>
                <p className="forgot-password-description">
                  Fyll i adressen för ditt Xpektor konto för att få ett mail med
                  en länk där du kan återställa ditt lösenord. Det kan ta upp
                  till en minut innan du får mailet.
                </p>
                <XpektorForm onSubmit={handleForgotSubmit}>
                  <XpektorForm.Input
                    label="Email"
                    type="text"
                    placeholder="john-smith@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    icon={faEnvelope}
                    infoText={
                      !errorText ? forgotRequestSent && 'Skickat!' : errorText
                    }
                    infoOk={!errorText}
                  />
                  <XpektorForm.Button
                    text={sendingForgot ? 'Skickar...' : 'Skicka'}
                    disabled={!validateForgotForm() || sendingForgot}
                  />
                </XpektorForm>
              </>
            )}
            <button
              className="forgot-password-button"
              type="button"
              onClick={() => {
                setAction(action === 'login' ? 'forgot-password' : 'login');
                setPassword('');
                setErrorText('');
              }}
            >
              <p>{action === 'login' ? 'Glömt lösenord?' : '<- Tillbaka'}</p>
            </button>
          </div>
          <div className="left-footer-container">
            <div className="contact">
              <p>
                Vill du testa Xpektor?{' '}
                <a
                  href="https://meetings-eu1.hubspot.com/jacob-liebermann?embed=true"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Boka demo
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="right-bg">
        <div className="right-content-container">
          <div className="right-header">
            <div className="salespitch-1">
              <p>Affärskritiska insikter i realtid</p>
            </div>
            <div className="salespitch-2">
              <p>Automatiserad rådgivning</p>
            </div>
          </div>
          <div className="screenshot" />
        </div>
      </div>
    </div>
  );
}
