/* eslint-disable react/prop-types */
import './XpektorForm.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

export default function XpektorForm({ children, onSubmit }) {
  return (
    <form onSubmit={onSubmit} className="xpektor-form">
      {children}
    </form>
  );
}

XpektorForm.Input = function Input({
  label,
  type,
  placeholder,
  value,
  onChange,
  icon,
  onIconClick,
  infoText,
  infoOk,
}) {
  return (
    <div className="xpektor-form-input-container">
      <label htmlFor={label}>{label}</label>
      <div className="xpektor-form-input">
        <input
          id={label}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e)}
        />
        {icon ? (
          <FontAwesomeIcon
            className={`form-input-icon ${onIconClick ? 'clickable-icon' : ''}`}
            icon={icon}
            onClick={onIconClick}
          />
        ) : (
          ''
        )}
      </div>

      <div className={`input-info-container ${infoOk && 'input-info-ok'}`}>
        {infoText ? (
          <>
            <FontAwesomeIcon className="input-info-icon" icon={faCircleInfo} />
            <p>{infoText}</p>{' '}
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

XpektorForm.Button = function Button({ text, disabled }) {
  return (
    <input
      className="xpektor-form-submit-button"
      type="submit"
      value={text}
      disabled={disabled}
    />
  );
};
