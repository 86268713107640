/* eslint-disable no-alert */
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AuthService from '../../Services/AuthService';

export default function AuthorizePresigned() {
  const history = useHistory();
  const { search } = useLocation();
  let url = '';

  useEffect(() => {
    const decodeToken = async () => {
      const state = new URLSearchParams(search).get('state');
      // eslint-disable-next-line react-hooks/exhaustive-deps
      url = await AuthService.decodePresigned(state);
      if (url) {
        history.push(`${url}`);
      }
    };
    decodeToken();
  }, []);
  return <div />;
}
