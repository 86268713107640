import './IconButton.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* eslint-disable react/prop-types */
export default function IconButton({ icon, title, active, onClick }) {
  return (
    <button
      className={`icon-button-wrapper ${active ? 'icon-button-active' : ''}`}
      type="button"
      onClick={onClick}
    >
      <FontAwesomeIcon className="icon-button-icon" icon={icon} />
      <p>{title}</p>
    </button>
  );
}
