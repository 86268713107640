import './CompanyInfo.scss';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Moment from 'moment';
import generatePDF, { Margin } from 'react-to-pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkSlash, faLink } from '@fortawesome/free-solid-svg-icons';
import ClientService from '../../../Services/ClientService';
import StatusService from '../../../Services/StatusService';
import UserService from '../../../Services/UserService';
import SimpleNotificationService from '../../../Services/SimpleNotificationService';
import LabeledInfo from '../../../Components/LabeledInfo/LabeledInfo';
import TabNav from '../../../Components/TabNav/TabNav';
import Equity from './Modules/Equity';
import Liquidity from './Modules/Liquidity';
import Management from './Modules/Management';
import Regulatory from './Modules/Regulatory';
import Accounts from './Modules/Accounts';
import Reports from './Modules/Reports';
import Score from './Modules/Score/Score';
import XpektorSlider from '../../../Components/XpektorSlider/XpektorSlider';
import XpektorButton from '../../../Components/XpektorButton/XpektorButton';
import BankConnectionModal from '../Modals/BankConnectionModal';
import ClientAuthLinkModal from '../Modals/ClientAuthLinkModal';
import GraphModal from '../Modals/GraphModal';

export default function CompanyInfo() {
  const { clientId } = useParams();
  const [client, setClient] = useState([]);
  const [clientLoaded, setClientLoaded] = useState(false); // Client can't be initizlied to null, so this is used as a nullcheck.
  const [bankGiro, setBankGiro] = useState(null);
  const [address, setAddress] = useState(null);

  const [authState, setAuthState] = useState({});

  const [equityStatus, setEquityStatus] = useState({});
  const [liquidityStatus, setLiquidityStatus] = useState({});
  const [managementStatus, setManagementStatus] = useState({});
  const [activeModule, setActiveModule] = useState('score');

  const [equityReportHistory, setEquityReportHistory] = useState([]);
  const [liquidityReportHistory, setLiquidityReportHistory] = useState([]);
  const [managementReportHistory, setManagementReportHistory] = useState([]);
  const [selectedGraphData, setSelectedGraphData] = useState([]);
  const [showGraphModal, setShowGraphModal] = useState(false);

  const [showConnectBankModal, setShowConnectBankModal] = useState(false);
  const [showClientAuthLinkModal, setShowClientAuthLinkModal] = useState(false);

  const [lastUpdated, setLastUpdated] = useState('N/A'); // Status skapad

  const formatDateTime = (dateTime) => {
    return Moment.utc(dateTime).local().format('YYYY-MM-DD HH:mm');
  };

  const onSelectStatus = (companyStatus) => {
    if (companyStatus != null && Object.keys(companyStatus).length > 0) {
      setLastUpdated(formatDateTime(companyStatus.dateCreated));
    } else {
      setLastUpdated('N/A');
    }
  };

  const showEquity = () => {
    setActiveModule('equity');
    onSelectStatus(equityStatus);
  };

  const showLiquidity = () => {
    setActiveModule('liquidity');
    onSelectStatus(liquidityStatus);
  };

  const showManagement = () => {
    setActiveModule('management');
    onSelectStatus(managementStatus);
  };

  const showRegulatory = () => {
    setActiveModule('regulatory');
    setLastUpdated('N/A');
  };

  const showAccounts = () => {
    setActiveModule('accounts');
    setLastUpdated('N/A');
  };

  const showReports = () => {
    setActiveModule('reports');
    setLastUpdated('N/A');
  };

  const showScore = () => {
    setActiveModule('score');
    setLastUpdated('N/A');
  };

  useEffect(() => {
    const getData = async () => {
      const clientResponse = await ClientService.getClient(clientId)
        .then((response) => {
          setClient(response.data);
          setClientLoaded(true);
          return response.data;
        })
        .catch(() => {});

      if (clientResponse.externalConnectionActive === true) {
        await ClientService.getClientSettings(clientId)
          .then((response) => {
            setAddress(
              `${response.data.address ?? ''}, ${
                response.data.postalCode ?? ''
              }, ${response.data.city ?? ''}`
            );
            setBankGiro(response.data.bankGiro);
          })
          .catch(() => {
            setAddress('N/A');
            setBankGiro('N/A');
          });

        await StatusService.getEquityLatest(clientId)
          .then((response) => {
            setEquityStatus(response.data);
          })
          .catch(() => {
            setEquityStatus(null);
          });

        await StatusService.getLiquidityLatest(clientId)
          .then((response) => {
            setLiquidityStatus(response.data);
          })
          .catch(() => {
            setLiquidityStatus(null);
          });

        await StatusService.getManagementLatest(clientId)
          .then((response) => {
            setManagementStatus(response.data);
          })
          .catch(() => {
            setManagementStatus(null);
          });
      } else {
        setAddress('N/A');
        setBankGiro('N/A');
        setLastUpdated('N/A');
        setEquityStatus(null);
        setLiquidityStatus(null);
        setManagementStatus(null);
      }

      const user = UserService.getCurrentUser();
      await SimpleNotificationService.SetViewed(clientId, user.id);
      setAuthState((state) => ({ ...state, user }));
    };

    getData();
  }, [clientId]);

  function CreatePdf(pdfContent) {
    return generatePDF(pdfContent, {
      filename: `${
        activeModule === 'equity'
          ? 'Eget_kapital'
          : activeModule === 'liquidity'
          ? 'Likviditet'
          : activeModule === 'management'
          ? 'Förvaltning'
          : activeModule === 'regulatory'
          ? 'Regulatoriskt'
          : activeModule === 'score'
          ? 'Score'
          : ''
      }-${Moment.utc().local().format('YYYY-MM-DD')}-${client.name}.pdf`,
      page: {
        margin: Margin.SMALL,
      },
    });
  }

  async function GetEquityHistory() {
    if (equityReportHistory.length !== 0) {
      setSelectedGraphData(equityReportHistory);
      return;
    }
    await StatusService.getEquityReportHistory(client.id)
      .then((response) => {
        setEquityReportHistory(response.data);
        setSelectedGraphData(response.data);
      })
      .catch(() => {});
  }

  async function GetLiquidityHistory() {
    if (liquidityReportHistory.length !== 0) {
      setSelectedGraphData(liquidityReportHistory);
      return;
    }
    await StatusService.getLiquidityReportHistory(client.id)
      .then((response) => {
        setLiquidityReportHistory(response.data);
        setSelectedGraphData(response.data);
      })
      .catch(() => {});
  }

  async function GetManagementHistory() {
    if (managementReportHistory.length !== 0) {
      setSelectedGraphData(managementReportHistory);
      return;
    }
    await StatusService.getManagementReportHistory(client.id)
      .then((response) => {
        setManagementReportHistory(response.data);
        setSelectedGraphData(response.data);
      })
      .catch(() => {});
  }

  const renderModules = () => {
    return (
      <XpektorSlider
        activeSlideIndex={
          activeModule === 'score'
            ? 0
            : activeModule === 'equity'
            ? 1
            : activeModule === 'liquidity'
            ? 2
            : activeModule === 'management'
            ? 3
            : activeModule === 'regulatory'
            ? 4
            : activeModule === 'accounts'
            ? 5
            : activeModule === 'reports'
            ? 6
            : ''
        }
      >
        <XpektorSlider.Slide hidden={activeModule !== 'score'}>
          <Score
            client={client}
            onPdfClick={(pdfContent) => CreatePdf(pdfContent)}
          />
        </XpektorSlider.Slide>
        <XpektorSlider.Slide hidden={activeModule !== 'equity'}>
          <Equity
            equityStatus={equityStatus}
            onPdfClick={(pdfContent) => CreatePdf(pdfContent)}
            onHistoryClick={() => {
              GetEquityHistory().then(() => {
                setShowGraphModal(true);
              });
            }}
          />
        </XpektorSlider.Slide>
        <XpektorSlider.Slide hidden={activeModule !== 'liquidity'}>
          <Liquidity
            liquidityStatus={liquidityStatus}
            onPdfClick={(pdfContent) => CreatePdf(pdfContent)}
            onHistoryClick={() => {
              GetLiquidityHistory().then(() => {
                setShowGraphModal(true);
              });
            }}
          />
        </XpektorSlider.Slide>
        <XpektorSlider.Slide hidden={activeModule !== 'management'}>
          <Management
            managementStatus={managementStatus}
            onPdfClick={(pdfContent) => CreatePdf(pdfContent)}
            onHistoryClick={() => {
              GetManagementHistory().then(() => {
                setShowGraphModal(true);
              });
            }}
          />
        </XpektorSlider.Slide>
        <XpektorSlider.Slide hidden={activeModule !== 'regulatory'}>
          <Regulatory
            client={client}
            skvConnected={client.skvConnected}
            onPdfClick={(pdfContent) => CreatePdf(pdfContent)}
          />
        </XpektorSlider.Slide>
        <XpektorSlider.Slide hidden={activeModule !== 'accounts'}>
          <Accounts client={client} />
        </XpektorSlider.Slide>
        <XpektorSlider.Slide hidden={activeModule !== 'reports'}>
          <Reports client={client} />
        </XpektorSlider.Slide>
      </XpektorSlider>
    );
  };

  return (
    <div className="company-info-wrapper">
      <div className="company-info-head">
        <div className="company-info-details">
          <div className="company-info-title-row">
            <p className="company-title">{client.name}</p>
          </div>
          <div className="company-info-sub-title-row">
            <LabeledInfo
              label="Org. nummer:"
              info={client.organizationNumber}
              animatedY
              animDelay={0.25}
              divider
            />
            {address != null && (
              <LabeledInfo
                label="Adress:"
                info={address}
                animatedY
                animDelay={0.5}
                divider
              />
            )}
            {bankGiro != null && (
              <LabeledInfo
                label="Bankgiro:"
                info={bankGiro}
                animatedY
                animDelay={0.75}
              />
            )}
          </div>
        </div>
        <div className="action-button-container">
          <div className="action-button-row">
            {clientLoaded === true && (
              <>
                <XpektorButton
                  content="Bokföring"
                  icon={client.externalConnectionActive ? faLink : faLinkSlash}
                  styleClass={
                    client.externalConnectionActive ? 'connected' : ''
                  }
                  disabled
                />
                <XpektorButton
                  content="Bank"
                  icon={client.bankConnectionActive ? faLink : faLinkSlash}
                  styleClass={
                    client.bankConnectionActive
                      ? 'connected'
                      : client.hadBankConnection
                      ? 'expired'
                      : ''
                  }
                  onClick={() => {
                    setShowConnectBankModal(true);
                  }}
                  disabled={
                    !client.bankConnectionActive && !client.hadBankConnection
                  }
                />
                {!client.unconnected && (
                  <XpektorButton
                    content="Skatteverket"
                    icon={client.skvConnected ? faLink : faLinkSlash}
                    styleClass={client.skvConnected ? 'connected' : ''}
                    disabled
                  />
                )}
              </>
            )}
          </div>
          <div className="sub-action-row">
            {authState.user?.role > 0 && (
              <XpektorButton
                content="Autentiseringslänk"
                onClick={() => setShowClientAuthLinkModal(true)}
              />
            )}
          </div>
        </div>
      </div>
      <div className="company-info-nav-container">
        <TabNav>
          <TabNav.Tab
            title="Score (BETA)"
            active={activeModule === 'score'}
            onClick={showScore}
          />
          <TabNav.Tab
            title="Eget kapital"
            active={activeModule === 'equity'}
            onClick={showEquity}
          />
          <TabNav.Tab
            title="Likviditet"
            active={activeModule === 'liquidity'}
            onClick={showLiquidity}
          />
          <TabNav.Tab
            title="Förvaltning"
            active={activeModule === 'management'}
            onClick={showManagement}
          />
          <TabNav.Tab
            title="Regulatoriskt"
            active={activeModule === 'regulatory'}
            onClick={showRegulatory}
          />
          <TabNav.Tab
            title="Kontoanalys"
            active={activeModule === 'accounts'}
            onClick={showAccounts}
          />
          <TabNav.Tab
            title="Rapporter"
            active={activeModule === 'reports'}
            onClick={showReports}
          />
        </TabNav>
      </div>
      <div className="sub-nav-container">
        {activeModule === 'equity' ||
        activeModule === 'liquidity' ||
        activeModule === 'management' ? (
          <div className="status">
            {clientLoaded === true && !client.externalConnectionActive ? (
              <>
                <FontAwesomeIcon
                  className="status-icon-broken error"
                  icon={faLinkSlash}
                />
                <p className="status-text error">
                  Bokföring ej kopplat. Kan inte hämta data.
                </p>
              </>
            ) : (
              ''
            )}
          </div>
        ) : activeModule === 'accounts' && client.hadBankConnection ? (
          <div className="status">
            <FontAwesomeIcon
              className="status-icon-broken error"
              icon={faLinkSlash}
            />
            <p className="status-text error">
              Bankkopplingen bruten. Datan uppdateras inte.
            </p>
          </div>
        ) : (
          ''
        )}
        {lastUpdated !== 'N/A' && (
          <LabeledInfo
            label="Värden uppdaterade: "
            info={lastUpdated}
            animatedY
            animDelay={0.25}
          />
        )}
      </div>
      {renderModules()}
      {showConnectBankModal && (
        <BankConnectionModal
          handleClose={() => setShowConnectBankModal(false)}
          client={client}
        />
      )}
      {showClientAuthLinkModal && (
        <ClientAuthLinkModal
          client={client}
          handleClose={() => setShowClientAuthLinkModal(false)}
        />
      )}
      {showGraphModal && (
        <GraphModal
          data={selectedGraphData}
          reportType={activeModule}
          handleClose={() => setShowGraphModal(false)}
        />
      )}
    </div>
  );
}
