/* eslint-disable react/prop-types */
import './UserTable.scss';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import {
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';

function TableHeaderItem({ label, onClick, orderIcon }) {
  return (
    <th>
      <button type="button" className="sortable" onClick={onClick}>
        {label}
      </button>
      <FontAwesomeIcon icon={orderIcon ?? faSort} />
    </th>
  );
}

export default function UserTable({ children, sortByName, order }) {
  const [arrowIcon, setArrowIcon] = useState();

  function OnSort() {
    setArrowIcon(order === 'asc' ? faSortUp : faSortDown);
  }

  return (
    <table className="user-table">
      <thead>
        <tr>
          <TableHeaderItem
            label="Namn"
            onClick={() => {
              sortByName();
              OnSort();
            }}
            orderIcon={arrowIcon}
          />
          {/* <th>Namn</th> */}
          <th>Email</th>
          <th>Användarroll</th>
          <th>Inställningar</th>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
}

function ItemButton({ icon, onClick }) {
  return (
    <button className="user-table-item-button" type="button" onClick={onClick}>
      <FontAwesomeIcon icon={icon} />
    </button>
  );
}

UserTable.Item = function Item({ user, onEditClick, onDeleteClick }) {
  return (
    <tr className="user-item-wrapper">
      <td>{user.name}</td>
      <td>{user.email}</td>
      <td>
        {
          {
            0: 'Användare',
            1: 'Admin',
            2: 'Integrerat konto',
            10: 'System Admin',
          }[user.role]
        }
      </td>
      <td>
        <ItemButton icon={faPenToSquare} onClick={onEditClick} />
        <ItemButton icon={faTrashCan} onClick={onDeleteClick} />
      </td>
    </tr>
  );
};
