/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import Moment from 'moment';
import TokenService from './TokenService';

const instance = axios.create({
  headers: { 'Content-Type': 'application/json' },
  baseURL: process.env.API_BASE_URL,
});

// TODO: Might rename this to middleware
instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    const newConfig = {
      ...config,
    };

    newConfig.headers['Content-Type'] = 'application/json';
    if (token) {
      newConfig.headers.Authorization = `Bearer ${token}`;
    }

    return newConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    function TokenExpired() {
      const now = Moment();
      const tokenExp = Moment(TokenService.getExp());

      if (now.isAfter(tokenExp)) return true;

      return false;
    }

    if (originalConfig.url !== `/api/v1/identity/login` && err.response) {
      if (
        err.response.status === 401 &&
        !originalConfig._retry &&
        TokenService.getLocalAccessToken() &&
        TokenExpired()
      ) {
        try {
          const rs = await instance.post(`/api/v1/identity/refresh`, {
            token: TokenService.getLocalAccessToken(),
            refreshToken: TokenService.getLocalRefreshToken(),
          });

          // New token
          TokenService.setToken(rs.data);
          return await instance(originalConfig);
        } catch {
          return Promise.reject(err);
        }
      }
    }
    return Promise.reject(err);
  }
);

export default instance;
