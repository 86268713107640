import './GraphModal.scss';
import { Button, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { ResponsiveLine } from '@nivo/line';

/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
export default function GraphModal({ handleClose, data, reportType }) {
  const [formattedData, setFormattedData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(100);
  const [stacked, setStacked] = useState(false);

  const clampToNegative = (value) => (Number(value) > 0 ? 0 : value);

  useEffect(() => {
    const formatData = () => {
      if (data.length < 2) {
        setFormattedData([]);
        return;
      }

      const orderedData = data.sort((a, b) => {
        const dateA = new Date(a.dateCreated);
        const dateB = new Date(b.dateCreated);

        if (dateA < dateB) {
          return -1;
        }
        if (dateA > dateB) {
          return 1;
        }
        return 0;
      });

      if (reportType === 'equity') {
        const bookedResultData = [];
        const calculatedResultData = [];
        const capitalToConsumeData = [];
        const taxOnCalculatedData = [];
        for (let i = 0; i < orderedData.length; i += 1) {
          bookedResultData.push({
            x: orderedData[i].dateCreated,
            y: orderedData[i].financialReport.result * -1,
          });
          calculatedResultData.push({
            x: orderedData[i].dateCreated,
            y: orderedData[i].financialReport.calculatedResultAfterTax,
          });
          capitalToConsumeData.push({
            x: orderedData[i].dateCreated,
            y:
              (orderedData[i].financialReport.equity +
                orderedData[i].financialReport.shareCapital / 2 +
                orderedData[i].financialReport.result) *
              -1,
          });
          taxOnCalculatedData.push({
            x: orderedData[i].dateCreated,
            y:
              clampToNegative(
                orderedData[i].financialReport.taxOnCalculatedResult
              ) +
              clampToNegative(
                orderedData[i].financialReport.nonDeductibleExpensesSum * -1
              ),
          });
        }

        setFormattedData([
          { id: 'Bokfört resultat', data: bookedResultData },
          { id: 'Prediktivt resultat', data: calculatedResultData },
          { id: 'Skatt på prediktivt', data: taxOnCalculatedData },
          { id: 'Kapital att förbruka', data: capitalToConsumeData },
        ]);
        setSelectedIds(['Bokfört resultat']);
      } else if (reportType === 'liquidity') {
        const bankBalanceData = [];
        const incomingPaymentsData = [];
        const outgoingPaymentsData = [];
        const predictiveCashFlowData = [];
        for (let i = 0; i < orderedData.length; i += 1) {
          bankBalanceData.push({
            x: orderedData[i].dateCreated,
            y: orderedData[i].liquidityReport.bankBalance,
          });
          incomingPaymentsData.push({
            x: orderedData[i].dateCreated,
            y: orderedData[i].liquidityReport.upcomingIncomingPayments,
          });
          outgoingPaymentsData.push({
            x: orderedData[i].dateCreated,
            y: orderedData[i].liquidityReport.upcomingOutgoingPayments,
          });
          predictiveCashFlowData.push({
            x: orderedData[i].dateCreated,
            y: orderedData[i].liquidityReport.predictiveCashFlow,
          });
        }
        setFormattedData([
          { id: 'Saldo bankkonton', data: bankBalanceData },
          { id: 'Kommande inbetalningar', data: incomingPaymentsData },
          { id: 'Kommande utbetalningar', data: outgoingPaymentsData },
          { id: 'Prediktivt kassaflöde', data: predictiveCashFlowData },
        ]);
        setSelectedIds(['Saldo bankkonton']);
      } else if (reportType === 'management') {
        const revenueData = [];
        const solidityData = [];
        const debtData = [];
        const grossProfitData = [];
        const operatingMarginData = [];
        const liquidityData = [];
        for (let i = 0; i < orderedData.length; i += 1) {
          revenueData.push({
            x: orderedData[i].dateCreated,
            y: orderedData[i].managementReport.revenue * -1,
          });
          solidityData.push({
            x: orderedData[i].dateCreated,
            y: orderedData[i].managementReport.solidityPercentage,
          });
          debtData.push({
            x: orderedData[i].dateCreated,
            y: orderedData[i].managementReport.debtPercentage,
          });
          grossProfitData.push({
            x: orderedData[i].dateCreated,
            y: orderedData[i].managementReport.grossProfitMargin,
          });
          operatingMarginData.push({
            x: orderedData[i].dateCreated,
            y: orderedData[i].managementReport.operatingMargin,
          });
          liquidityData.push({
            x: orderedData[i].dateCreated,
            y: orderedData[i].managementReport.liquidity,
          });
        }
        setFormattedData([
          { id: 'Omsättning', data: revenueData },
          { id: 'Soliditet', data: solidityData },
          { id: 'Skuldsättningsgrad', data: debtData },
          { id: 'Bruttomarginal', data: grossProfitData },
          { id: 'Rörelsemarginal', data: operatingMarginData },
          { id: 'Kassalikviditet', data: liquidityData },
        ]);
        setSelectedIds(['Omsättning']);
      }
    };
    if (Object.keys(data).length !== 0 && reportType) formatData();
  }, [data, reportType]);

  useEffect(() => {
    if (selectedIds.length === 0) return;

    let flattenedArray = [];
    formattedData.forEach((lineData) => {
      if (selectedIds.some((id) => id === lineData.id)) {
        flattenedArray = [...flattenedArray, ...lineData.data];
      }
    });

    const highestValue = Math.max(...flattenedArray.map((item) => item.y));
    const lowestValue = Math.min(...flattenedArray.map((item) => item.y));
    const delta = highestValue - lowestValue;
    const minMaxPadding = Math.abs(
      delta !== 0 ? delta * 0.1 : highestValue * 0.1
    );
    setMinValue(lowestValue - minMaxPadding);
    setMaxValue(highestValue + minMaxPadding);
  }, [selectedIds, formattedData]);

  const availableColors = [
    '#2E5BFF',
    '#56FD10',
    '#FFDE59',
    '#FF9F5B',
    '#FF5B5B',
    '#DB5BFF',
    '#5BFFE4',
    '#FF5BC0',
  ];

  const lineChartProperties = {
    enablePoints: false,
    useMesh: true,
    lineWidth: '2px',
    margin: { top: 10, right: 30, bottom: 30, left: 70 },
    yScale: {
      type: 'linear',
      stacked,
      min: stacked ? 'auto' : minValue,
      max: stacked ? 'auto' : maxValue,
    },
    colors: availableColors,
    yFormat: '>-.3s',
    xFormat: (value) => Moment(value).utc().format('YYYY-MM'),
    axisLeft: {
      format: '>-.3s',
    },
    axisBottom: {
      format: (value) => Moment(value).utc().format('YYYY-MM'),
    },
    legends: [
      {
        anchor: 'bottom-left',
        direction: 'column',
        justify: false,
        translateX: 10,
        translateY: 0,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 150,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        effects: [
          {
            on: 'hover',
            style: {
              itemBackground: 'rgba(0, 0, 0, .03)',
              itemOpacity: 1,
            },
          },
        ],
      },
    ],
  };

  function ToggleData(id) {
    if (selectedIds.indexOf(id) !== -1) {
      setSelectedIds(
        selectedIds.filter((item) => {
          return item !== id;
        })
      );
    } else {
      setSelectedIds((oldArray) => [...oldArray, id]);
    }
  }

  return (
    <Modal
      show
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="border-0 graph-modal-wrapper"
    >
      <Modal.Header>
        <Modal.Title>{`Historik - ${
          reportType === 'equity'
            ? 'Eget kapital'
            : reportType === 'liquidity'
            ? 'Likviditet'
            : reportType === 'management'
            ? 'Förvaltning'
            : 'Annat'
        }`}</Modal.Title>
        <Button className="btn-close" bsPrefix="button" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body className="border-0">
        <div className="graph-modal-header">
          <p>
            Obs! Historik är under utveckling och man får just nu bara data
            sedan klienten kopplades på.
          </p>
        </div>
        <div className="graph-modal-content">
          <div className="graph-select-column">
            {formattedData.map((lineData) => {
              const isActive = selectedIds.some(
                (element) => lineData.id === element
              );
              return (
                <button
                  className={`graph-legend-button ${
                    isActive && 'toggle-active'
                  }`}
                  type="button"
                  onClick={() => ToggleData(lineData.id)}
                >
                  <FontAwesomeIcon
                    className="toggle-icon"
                    icon={isActive ? faSquareCheck : faSquare}
                  />{' '}
                  <p>{lineData.id}</p>
                </button>
              );
            })}
          </div>
          <div className="graph-display-column">
            <div className="graph-controls-row">
              <button
                className={`graph-control-button ${stacked && 'toggle-active'}`}
                type="button"
                onClick={() => setStacked(!stacked)}
              >
                <FontAwesomeIcon
                  className="toggle-icon"
                  icon={stacked ? faSquareCheck : faSquare}
                />
                <p>Relativa</p>
              </button>
            </div>
            <div className="graph-display">
              {formattedData.length === 0 ? (
                <p className="graph-no-content">
                  Finns ännu ingen historisk data
                </p>
              ) : selectedIds.length === 0 ? (
                <p className="graph-no-content">Ingen data vald</p>
              ) : (
                <ResponsiveLine
                  {...lineChartProperties}
                  data={formattedData.filter((lineData) => {
                    return selectedIds.some((dataId) => dataId === lineData.id);
                  })}
                />
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
