import { Container, Image, Row } from 'react-bootstrap';
import LandingNavigation from '../LandingNavigation/LandingNavigation';
import Contact from '../../../Components/Contact/Contact';
import Daniel from '../../../Images/About/Daniel.png';
import Hanna from '../../../Images/About/Hanna 1.png';
import './About.scss';

export default function Vision() {
  return (
    <>
      <LandingNavigation />
      <div style={{ height: 100 }} />
      <Container className="titleContainer mt-5">
        <h2 className="d-none d-md-block">
          <span>Vår vision är att med</span> <br />
          <span>prediktiva resultat</span> <br />
          <span className="miniTitle">skapa ett hållbart företagande</span>
        </h2>
        <h2 className="d-block d-md-none">
          Vår vision är att med prediktiva resultat skapa
          <span className="miniTitle"> ett hållbart företagande</span>
        </h2>
      </Container>
      <Row className="mx-lg-5 my-5">
        <Container className="aboutCard col-12 col-lg-6 px-5">
          <Row>
            <Container className="col-6 text-end">
              <Image fluid src={Daniel} alt="Daniel" />
            </Container>
            <Container className="col-6">
              <h5>Daniel Eremar</h5>
              <p>
                Entrepenören från Laholm med 12 års erfarenhet som
                redovisningskonsult. Kört egen redovisningsbyrå i många år och
                drivs av att hitta nya lösningar som gör det enklare för bolag
                att fokusera på sin verksamhet.
              </p>
            </Container>
          </Row>
        </Container>
        <Container className="aboutCard col-12 col-lg-6 px-5">
          <Row>
            <Container className="col-6 text-end">
              <Image fluid src={Hanna} alt="Hanna" />
            </Container>
            <Container className="col-6">
              <h5>Hanna Gårdmark</h5>
              <p>
                Auktoriserad redovisningskonsult med 6 års erfarenhet i bagaget.
                Drivit egen byrå i flera år. Letar hela tiden efter smidigare
                sätt att arbeta som ger mer tid åt rådgivande och som skapar
                mervärde åt kunderna.
              </p>
            </Container>
          </Row>
        </Container>
      </Row>
      <Container className="px-5 py-5">
        <h3>Om oss</h3>
        <p>
          Xpektor har sitt ursprung i redovisningsbranschen och våra grundare
          hade en tydlig ambition att förändra redovisningsekonomens arbetssätt.
          Detta då de såg stora utmaningar i att ständigt behöva jobba i det
          förflutna. Att i det dagliga arbetet behöva utgå från bokförda
          händelser och historisk data kändes varken modernt, aktuellt eller
          särskilt framtidsorienterat.{' '}
        </p>
        <p>
          Teamet bakom Xpektor visste att det skulle gå att skapa långt mycket
          mer värde genom att prediktivt se vad som är på väg att hända i ett
          företag snarare än att agera på sådant som redan hänt. Idén om Xpektor
          och möjligheten att blicka in i framtiden tog sin form. Ett intensivt
          arbete påbörjades och idag är Xpektor helt unika med att kombinera
          information och skapa insikter från affärssystem, bank, Skatteverket
          och Bolagsverket.
        </p>
        <p>
          Xpektor har utvecklats till ett kraftigt och uppskattat verktyg som
          används av långt fler än bara redovisningskonsulter så som banker,
          kreditbolag och kommuner. Användningsområdena är många och fördelarna
          lika så.
        </p>
        <p>
          <a
            href="https://meetings-eu1.hubspot.com/jacob-liebermann?embed=true"
            target="_blank"
            rel="noopener noreferrer"
          >
            Klicka här för att komma i kontakt
          </a>
        </p>

        <header />
      </Container>
      <footer className="col-12">
        <Contact />
      </footer>
    </>
  );
}
