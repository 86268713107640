import api from './ApiMiddleware';
import monkeyApi from './MonkeyApiMiddleware';

const getClient = (id) => {
  return api.get(`/api/v1/client/${id}`);
};

const getAllClients = () => {
  return api.get(`/api/v1/user/clients`);
};

const createClient = (name, organizationNumber) => {
  return api.post(`/api/v1/client`, {
    name,
    organizationNumber,
  });
};

const getClientSettings = (clientId) => {
  return api.get(`/api/v1/client/${clientId}/companysettings`);
};

const getRegulatoryInfo = (clientId) => {
  return api.get(`/api/v1/regulatory/info/${clientId}`);
};

const getBasicCompanyInfo = (orgNumber) => {
  return api.post(`/api/v1/companyinfo/${orgNumber}`);
};

const getActiveDirectors = (clientInfoId) => {
  return api.get(`/api/v1/companyinfo/directors/${clientInfoId}`);
};

const getInactiveDirectors = (clientInfoId) => {
  return api.get(`/api/v1/companyinfo/inactivedirectors/${clientInfoId}`);
};

const getPayrollData = (clientInfoId) => {
  return api.get(`/api/v1/companyinfo/payrolldata/${clientInfoId}`);
};

const updateClient = (client) => {
  return api.put(`/api/v1/client/${client.id}`, {
    organizationNumber: client.organizationNumber,
    name: client.name,
  });
};

const updateClientAuth = async (clientId, authCode, partnerId) => {
  const response = await api.put(
    `/api/v1/client/${clientId}/authorize/${authCode}/${partnerId}`
  );
  return response.data;
};

const authenticateAccounting = async (
  authorizationCode,
  partnerId,
  externalStateId
) => {
  if (externalStateId !== null) {
    const response = await monkeyApi.post(`/api/v1/client/authorize`, {
      authorizationCode,
      partnerId,
      externalStateId,
    });

    return response;
  }

  const response = await api.post(`/api/v1/client/authorize`, {
    authorizationCode,
    partnerId,
    externalStateId,
  });

  return response;
};

const deleteClient = async (clientId, customerId) => {
  const response = await api.delete(`/api/v1/client/${clientId}/${customerId}`);
  return response;
};

const ClientService = {
  getClient,
  getAllClients,
  createClient,
  getClientSettings,
  getRegulatoryInfo,
  getBasicCompanyInfo,
  getActiveDirectors,
  getInactiveDirectors,
  getPayrollData,
  updateClient,
  updateClientAuth,
  authenticateAccounting,
  deleteClient,
};

export default ClientService;
