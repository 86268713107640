import jwt from 'jwt-decode';

const getLocalRefreshToken = () => {
  const token = JSON.parse(localStorage.getItem('token'));
  return token?.refreshToken;
};

const getLocalAccessToken = () => {
  const token = JSON.parse(localStorage.getItem('token'));
  return token?.token;
};

const getLocalUser = () => {
  const token = JSON.parse(localStorage.getItem('token'));
  return token?.user;
};

const getExp = () => {
  const token = JSON.parse(localStorage.getItem('token'));
  const decodedToken = jwt(token?.token);
  return decodedToken.exp * 1000;
};

const setToken = (token, tokenNumber) => {
  localStorage.setItem('token', JSON.stringify(token));
  localStorage.setItem('token-number', !tokenNumber ? 1 : tokenNumber);
};

const removeToken = () => {
  localStorage.removeItem('token');
};

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  getLocalUser,
  setToken,
  removeToken,
  getExp,
};

export default TokenService;
