import api from './ApiMiddleware';

const SetViewed = (clientId, userId) => {
  return api.post(`/api/v1/notification/viewed`, {
    clientId,
    userId,
  });
};

const SimpleNotificationService = {
  SetViewed,
};

export default SimpleNotificationService;
