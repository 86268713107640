/* eslint-disable react/prop-types */
import './LabeledInfo.scss';

export default function LabeledInfo({
  label,
  info,
  animatedX,
  animatedY,
  animDelay = 0,
  divider,
}) {
  const animationStyle = {
    animationDelay: `${animDelay}s`,
  };

  return (
    <div className="labeled-time-mask">
      <div
        style={animationStyle}
        className={`labeled-time-content ${
          animatedY ? 'anim-y' : animatedX ? 'anim-x' : ''
        } ${divider ? 'divider' : ''}`}
      >
        <p className="label">{label}</p>
        <p className="value">{info}</p>
      </div>
    </div>
  );
}
