/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import ReactCountryFlag from 'react-country-flag';
import Searchbar from '../../../Components/Searchbar/Searchbar';
import Dropdown from '../../../Components/Dropdown/Dropdown';

export default function BankAuthStep({
  completed,
  banks,
  countries,
  selectedCountry,
  onCountryClick,
  redirectToBank,
  error,
  errorText,
}) {
  const [searchQuery, setSearchQuery] = useState('');
  const [countryDropdownOpen, setCountryDropdownOpen] = useState(false);

  const filteredBanks = searchQuery
    ? banks.filter((bank) => {
        return bank.name?.toLowerCase().includes(searchQuery?.toLowerCase());
      })
    : banks;

  return (
    <div className="auth-step">
      {completed ? (
        <div className="auth-step-completed-content">
          <FontAwesomeIcon
            className="auth-complete-icon"
            icon={faCircleCheck}
          />
          <p className="auth-complete-header">Banken är kopplad!</p>
        </div>
      ) : (
        <>
          <p className="auth-step-header">
            Koppla på <b>FÖRETAGETS</b> bankkonto
          </p>
          <p className="auth-step-description">
            Genom att koppla ditt <i>företagskonto</i> till Xpektor slipper du
            manuellt samla in och skicka bankutdrag. Xpektor hämtar automatiskt
            den information som behövs vilket underlättar och snabbar på
            hanteringen av din förfrågan.
          </p>
          {error ? (
            <div className="auth-step-error">
              <p>
                {errorText ??
                  'Något gick fel och det gick inte att koppla banken.'}
              </p>
            </div>
          ) : (
            ''
          )}
          <div className="bank-nav">
            <Searchbar
              placeholder="Sök bank"
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Dropdown
              open={countryDropdownOpen}
              title={selectedCountry.name}
              componentIcon={
                <ReactCountryFlag countryCode={selectedCountry.code} svg />
              }
              onClick={() => setCountryDropdownOpen(!countryDropdownOpen)}
              onClickOutside={() => setCountryDropdownOpen(false)}
              right
            >
              {countries.map((country) => {
                return (
                  <Dropdown.Item
                    key={country.code}
                    content={
                      <div className="country-item">
                        <ReactCountryFlag countryCode={country.code} svg />
                        <p>{country.name}</p>
                      </div>
                    }
                    onClick={() => {
                      setCountryDropdownOpen(false);
                      onCountryClick(country);
                    }}
                  />
                );
              })}
            </Dropdown>
          </div>
          <div className="bank-grid">
            {filteredBanks.map((data) => {
              return (
                <div
                  className="bank-button"
                  onClick={() =>
                    redirectToBank(data.name, data.country, data.psuType)
                  }
                  role="button"
                  tabIndex="0"
                  key={`${data.name} ${data.country} ${data.bic} ${data.psuType}`}
                >
                  <div className="button-content-container">
                    <p>{`${data.name} (${
                      data.psuType === 0 ? 'Business' : 'Personal'
                    })`}</p>
                    <img
                      className="bank-image"
                      src={data.logo}
                      alt="Bank logo"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}
