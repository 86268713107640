import api from './ApiMiddleware';

const DeleteRecipient = (userId, clientId) => {
  return api.delete(`/api/v1/client/${clientId}/recipient/user/${userId}`);
};

const PostRecipient = (userId, clientId) => {
  return api.post(`/api/v1/client/${clientId}/recipient/user/${userId}`);
};

const GetRecipientsByClient = (clientId) => {
  return api.get(`/api/v1/client/${clientId}/recipients`);
};
const GetRecipientsByUser = (userId) => {
  return api.get(`/api/v1/user/recipients/${userId}`);
};

const RecipientService = {
  DeleteRecipient,
  PostRecipient,
  GetRecipientsByClient,
  GetRecipientsByUser,
};

export default RecipientService;
