/* eslint-disable react/prop-types */
import './FilterComponents.scss';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faSquareCheck,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';

export function FilterDropdown({ title, children }) {
  const [expanded, setExpanded] = useState(true);

  return (
    <div className="filter-dropdown-wrapper">
      <button
        className="filter-dropdown-button"
        type="button"
        onClick={() => setExpanded(!expanded)}
      >
        <p>{title}</p>
        <FontAwesomeIcon
          className="dropdown-icon"
          icon={expanded ? faChevronUp : faChevronDown}
        />
      </button>
      {expanded ? <div className="filter-content">{children}</div> : ''}
    </div>
  );
}

export function ToggleButton({ onClick, text, active, count }) {
  return (
    <button
      type="button"
      className={`toggle-button ${active && 'toggle-active'}`}
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon={active ? faSquareCheck : faSquare}
        className="toggle-icon"
      />
      <p>{text}</p>
      {count !== null ? <p className="item-count">{count}</p> : ''}
    </button>
  );
}

export function ClearButton({ onClick, text, disabled }) {
  return (
    <button
      className="clear-filter-button"
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      <FontAwesomeIcon className="clear-filter-icon" icon={faXmark} />
      <p>{text}</p>
    </button>
  );
}
