import './SystemAdmin.scss';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import UserService from '../../../Services/UserService';
import TabNav from '../../../Components/TabNav/TabNav';
import XpektorSlider from '../../../Components/XpektorSlider/XpektorSlider';
import ServiceStatusSettings from './ServiceStatusSettings/ServiceStatusSettings';

export default function SystemAdmin() {
  const [activeTab, setActiveTab] = useState('');
  const history = useHistory();

  const isLocation = (path) => {
    return history.location.pathname.includes(path);
  };

  useEffect(() => {
    const getData = async () => {
      const user = await UserService.getCurrentUser();
      if (user.role !== 10) {
        history.push('/dashboard');
      }

      if (isLocation('drift')) {
        setActiveTab('drift');
      } else if (isLocation('other')) {
        setActiveTab('other');
      }
    };
    getData();
  }, []);

  return (
    <div className="system-admin-wrapper">
      <div className="system-admin-head">
        <p className="system-admin-title">System Admin</p>
      </div>
      <div className="system-admin-nav-container">
        <TabNav>
          <TabNav.Tab
            title="Drift"
            active={activeTab === 'drift'}
            onClick={() => {
              setActiveTab('drift');
              history.push(`/systemadmin/drift`);
            }}
          />
          <TabNav.Tab
            title="Annat"
            active={activeTab === 'other'}
            onClick={() => {
              setActiveTab('other');
              history.push(`/systemadmin/other`);
            }}
          />
        </TabNav>
      </div>
      <XpektorSlider
        activeSlideIndex={
          activeTab === 'drift' ? 0 : activeTab === 'other' ? 1 : ''
        }
      >
        <XpektorSlider.Slide hidden={activeTab !== 'drift'}>
          <ServiceStatusSettings />
        </XpektorSlider.Slide>
        <XpektorSlider.Slide hidden={activeTab !== 'other'}>
          <div />
        </XpektorSlider.Slide>
      </XpektorSlider>
    </div>
  );
}
