import { Image, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import LandingNavigation from '../LandingNavigation/LandingNavigation';
import Contact from '../../../Components/Contact/Contact';
import './Offers.scss';
import XpektorLogo from '../../../Images/Logos/Mono.png';
import AccountecLogo from '../../../Images/Logos/Accountec_byrastod_i 1.svg';
import OfferCircle from '../../../Images/Offers/lansering.svg';

export default function Offers() {
  return (
    <>
      <LandingNavigation />
      <header />
      <div>
        <h1 className="offerTitle text-center mb-5 px-5">
          Nu får du som användare av Accountec ta del av vårat grymma
          erbjudande!
        </h1>
        <header />
        <div className="offerbox pt-5">
          <div className="purpleCircle">
            <Image fluid src={OfferCircle} />
          </div>
          <div className="blueRectangle fluid p-5">
            <p className="startTxt ps-2">
              Ny Kund{' '}
              <h4 className="text-black">
                <strong>Baspaket</strong>
              </h4>
            </p>
            <h2>
              25<span className="smallTxt">kr/månad</span>
            </h2>
            <p className="perClient">per klient*</p>
            <Row className="ps-3">
              <div className="col-1">
                <FontAwesomeIcon
                  icon={faArrowAltCircleRight}
                  className="pt-3"
                />
              </div>
              <div className="col-11">
                <p className="moduleTxt">
                  Eget Kapital
                  <span className="moduleMiniTxt">
                    {' '}
                    ord. pris 89 kr / månad
                  </span>
                </p>
              </div>
            </Row>
            <div className="contactTxt">
              <p>Kontakta oss för att ta del av lanseringserbjudandet:</p>
              <p>
                Daniel Eremar
                <br />
                0769424744
                <br />
                daniel@wecoorp.com
              </p>
            </div>
            <p className="footnoteTxt pt-3">
              *För varje extern användare som kopplas på tillkommer ytterligare
              25 kr/användare.
            </p>
          </div>
        </div>

        <Row className="py-5 my-5">
          <div className="col-12 col-md-5 text-center text-md-end pt-md-5">
            <Image src={XpektorLogo} style={{ width: 200 }} />
          </div>
          <div className="col-12 col-md-2 pt-3 pb-3 pt-md-5 pt-lg-4">
            <h1 className="text-center">X</h1>
          </div>
          <div className="col-12 col-md-5 ps-4 ps-md-1 pt-md-2 text-center text-md-start">
            <Image src={AccountecLogo} style={{ width: 200 }} />
          </div>
        </Row>
      </div>
      <footer>
        <Contact />
      </footer>
    </>
  );
}
