import api from './ApiMiddleware';

const GetUserClientAccessOnUserId = (userId) => {
  return api.get(`/api/v1/user/${userId}/userclientaccesses`);
};

const GetUserClientAccessOnClientId = (clientId) => {
  return api.get(`/api/v1/client/userclientaccesses/${clientId}`);
};

const GetOrCreateUserClientAccess = (userId, clientId) => {
  return api.post(`/api/v1/user/userclientaccess`, {
    userId,
    clientId,
  });
};

const DeleteUserClientAccess = (userId, clientId) => {
  return api.delete(`/api/v1/user/${userId}/userclientaccess/${clientId}`);
};

const UserClientAccessService = {
  GetUserClientAccessOnUserId,
  GetUserClientAccessOnClientId,
  GetOrCreateUserClientAccess,
  DeleteUserClientAccess,
};

export default UserClientAccessService;
