import './Navigation.scss';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Searchbar from '../../../Components/Searchbar/Searchbar';
import UserService from '../../../Services/UserService';
import ServiceStatusDisplay from '../../../Components/ServiceStatusPopup/ServiceStatusDisplay';
import StatusService from '../../../Services/StatusService';

export default function Navigation() {
  const [authState, setAuthState] = useState({});
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState('');
  const [serviceStatusMessages, setServiceStatusMessages] = useState([]);
  // const [pollMessages, setPollMessages] = useState(true);

  useEffect(() => {
    const user = UserService.getCurrentUser();

    setAuthState((state) => ({ ...state, user }));

    const getServiceStatusMessages = async () => {
      await StatusService.getServiceStatusMessages()
        .then((response) => {
          setServiceStatusMessages(response.data);
        })
        .catch(() => {
          setServiceStatusMessages([]);
        });
    };
    getServiceStatusMessages();
  }, []);

  const onSubmit = (e) => {
    history.push(`/dashboard/?s=${searchQuery}`);
    e.preventDefault();
  };

  /* const [count, setCount] = useState(0);
  const getServiceStatusMessages = async () => {
    setCount(count + 1);
    await StatusService.getServiceStatusMessages()
      .then((response) => {
        setServiceStatusMessages(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setPollMessages(false);
        }
        setServiceStatusMessages([]);
      });
  };

  useEffect(() => {
    const timer = setTimeout(
      () => pollMessages && getServiceStatusMessages(),
      60000
    );
    return () => clearTimeout(timer);
  }, [count]); */

  return (
    <div className="navbar-wrapper">
      <div className="content-container">
        <Searchbar
          placeholder="Sök klient"
          onChange={(e) => setSearchQuery(e.target.value)}
          onSubmit={onSubmit}
        />
        {serviceStatusMessages !== null && serviceStatusMessages.length > 0 ? (
          <ServiceStatusDisplay alerts={serviceStatusMessages} />
        ) : (
          ''
        )}
        <div className="user-name">
          <p>{authState?.user?.name ?? ''}</p>
        </div>
      </div>
    </div>
  );
}
