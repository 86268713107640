/* eslint-disable react/prop-types */
import './ClientTable.scss';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPenToSquare,
  faUser,
  faBell,
  faTrashCan,
} from '@fortawesome/free-regular-svg-icons';
import {
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';

function TableHeaderItem({ label, onClick, orderIcon }) {
  return (
    <th>
      <button type="button" className="sortable" onClick={onClick}>
        {label}
      </button>
      <FontAwesomeIcon icon={orderIcon ?? faSort} />
    </th>
  );
}

export default function ClientTable({ children, sortByName, order }) {
  const [arrowIcon, setArrowIcon] = useState();

  function OnSort() {
    setArrowIcon(order === 'asc' ? faSortUp : faSortDown);
  }

  return (
    <table className="client-table">
      <thead>
        <tr>
          <TableHeaderItem
            label="Klientnamn"
            onClick={() => {
              sortByName();
              OnSort();
            }}
            orderIcon={arrowIcon}
          />
          <th>Organisationsnummer</th>
          <th>Inställningar</th>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
}

function ItemButton({ icon, onClick }) {
  return (
    <button
      className="client-table-item-button"
      type="button"
      onClick={onClick}
    >
      <FontAwesomeIcon icon={icon} />
    </button>
  );
}

ClientTable.Item = function Item({
  client,
  onNotificationClick,
  onUserAccessClick,
  onEditClick,
  onDeleteClick,
}) {
  return (
    <tr className="client-item-wrapper">
      <td>{client.name}</td>
      <td>{client.organizationNumber}</td>
      <td>
        <ItemButton icon={faBell} onClick={onNotificationClick} />
        <ItemButton icon={faUser} onClick={onUserAccessClick} />
        <ItemButton icon={faPenToSquare} onClick={onEditClick} />
        <ItemButton icon={faTrashCan} onClick={onDeleteClick} />
      </td>
    </tr>
  );
};
