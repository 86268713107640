import './Contact.scss';

function Contact() {
  return (
    <div className="footer-contact-container">
      <div className="contact-item">
        <ul>
          <li>Hanna Gårdmark</li>
          <li>CEO</li>
          <li>+46 720142474</li>
          <li>hanna@wecoorp.com</li>
        </ul>
      </div>
      <div className="contact-item">
        <ul>
          <li>Daniel Eremar</li>
          <li>Head of sales</li>
          <li>+46 769424744</li>
          <li>daniel@wecoorp.com</li>
        </ul>
      </div>
      <div className="link-item">
        <a href="/privacypolicy">Integritetspolicy</a>
        <a href="/termsandconditions">Regler och villkor</a>
      </div>
    </div>
  );
}

export default Contact;
