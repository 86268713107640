import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Alert, CircularProgress } from '@mui/material';
import ClientService from '../../../../Services/ClientService';
import CreateClientPopUp from './Modals/CreateClientPopUp';
import XpektorButton from '../../../../Components/XpektorButton/XpektorButton';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function AuthenticateClient(handleClose) {
  const [processNumber, setProcessNumber] = useState(1);
  const [showCreateClient, setShowCreateClient] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [clientInfo, setClientInfo] = useState([]);
  const { search } = useLocation();
  const action = new URLSearchParams(search).get('action');
  const authCode = new URLSearchParams(search).get('authCode');
  const clientId = new URLSearchParams(search).get('clientId');
  const partnerId = new URLSearchParams(search).get('partnerId');
  // eslint-disable-next-line no-return-assign
  const cleanUrl = () => (window.location = '/settings/clients');

  const translateError = (error) => {
    switch (error) {
      case 'Invoices':
        return 'Kundfakturor';
      case 'SupplierInvoices':
        return 'Leverantörsfakturor';
      case 'Vouchers':
        return 'Verifikat';
      default:
        return error;
    }
  };

  /* eslint-disable no-console */
  useEffect(() => {
    const authenticateClient = async () => {
      // eslint-disable-next-line @typescript-eslint/no-shadow

      const errorType = new URLSearchParams(search).get('error_type');
      if (errorType !== null) {
        const errorDescription = new URLSearchParams(search).get(
          'error_description'
        );

        setErrorMessage(
          `Något gick fel och det gick inte att koppla bokföringssystemet. ${
            errorDescription !== null
              ? `Felmeddelande: ${errorDescription}`
              : ''
          }`
        );
        setProcessNumber(3);
        return;
      }

      if (action === 'createClient') {
        await ClientService.authenticateAccounting(
          authCode,
          partnerId !== null ? Number(partnerId) : null,
          null
        )
          .then((response) => {
            setClientInfo(response.data);
            setProcessNumber(2);
          })
          .catch((error) => {
            if (error.response.status === 406) {
              setErrorMessage(
                `Du har inte behörighet att autentisera den nya klienten. Anledning: Du saknar licens för 
                  ${translateError(error.response.data.message)}`
              );
            }
            if (error.response.status === 404) {
              setErrorMessage('Kunde inte autentisera den nya klienten');
            }
            if (error.response.status === 500) {
              setErrorMessage('Något gick fel försök igen om en stund');
            }
            setProcessNumber(3);
          });
      }

      if (action === 'updateClient') {
        await ClientService.updateClientAuth(
          clientId,
          authCode,
          Number(partnerId)
        )
          .then((response) => {
            console.log(response);
            setProcessNumber(2);
          })
          .catch((error) => {
            if (error.response.status === 406) {
              setErrorMessage(
                `Du har inte behörighet att autentisera den nya klienten. Anledning: Du saknar licens för 
                  ${translateError(error.response.data.message)}`
              );
            }
            if (error.response.status === 404) {
              setErrorMessage('Kunde inte autentisera den nya klienten');
            }
            if (error.response.status === 500) {
              setErrorMessage('Något gick fel försök igen om en stund');
            }
            setProcessNumber(3);
          });
      }
    };

    // Use this function instead of authenticate client when you want to test the modal
    // Remember to include the correct search params when doing so
    /* const windowTest = async () => {
      await ClientService.getClient(clientId).then((response) => {
        setClientInfo(response.data);
        setProcessNumber(2);
      });
    }; */
    authenticateClient();
    // windowTest();
  }, [action]);
  return (
    <>
      <Modal
        show
        onHide={cleanUrl}
        backdrop="static"
        keyboard={false}
        className="border-0"
      >
        <Modal.Header className="border-0">
          {action === 'createClient' ? (
            <Modal.Title>Skapa kontakt</Modal.Title>
          ) : (
            <Modal.Title>Verifiera om klienten</Modal.Title>
          )}

          <Button className="btn-close" bsPrefix="button" onClick={cleanUrl} />
        </Modal.Header>
        {processNumber === 1 ? (
          <Modal.Body className="border-0">
            <div className="text-center">
              <CircularProgress />
            </div>
            <br />
          </Modal.Body>
        ) : processNumber === 2 ? (
          <Modal.Body className="border-0">
            {action === 'createClient' ? (
              <Alert severity="success">
                Du är nu kopplad till
                {partnerId === '1'
                  ? ' Fortnox '
                  : partnerId === '2'
                  ? ' Visma '
                  : ''}
                - verifiera klienten nedan!
              </Alert>
            ) : (
              <Alert severity="success">
                Allt gick bra. Du har nu veriferat om klienten.
              </Alert>
            )}
            <br />
            {action === 'createClient' ? (
              <div className="d-flex justify-content-between">
                <XpektorButton
                  content="Verifiera klienten"
                  onClick={() => {
                    setShowCreateClient(true);
                  }}
                />
              </div>
            ) : (
              <Button onClick={cleanUrl}>Ok</Button>
            )}
          </Modal.Body>
        ) : processNumber === 3 ? (
          <Modal.Body className="border-0">
            <Alert severity="error">{`${errorMessage}`}</Alert>
            <br />
            <XpektorButton content="Stäng" onClick={cleanUrl} />
          </Modal.Body>
        ) : (
          ''
        )}
      </Modal>

      {showCreateClient && (
        <CreateClientPopUp
          show={showCreateClient}
          handleClose={() => setShowCreateClient(false)}
          clientToEdit={clientInfo}
          action="createClient"
        />
      )}
    </>
  );
}
