/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import MonkeyTokenService from './MonkeyTokenService';

const monkeyInstance = axios.create({
  headers: { 'Content-Type': 'application/json' },
  baseURL: process.env.API_BASE_URL,
});

// TODO: Might rename this to middleware
monkeyInstance.interceptors.request.use(
  (config) => {
    const token = MonkeyTokenService.getLocalAccessToken();
    const newConfig = {
      ...config,
    };

    newConfig.headers['Content-Type'] = 'application/json';
    if (token) {
      newConfig.headers.Authorization = `Bearer ${token}`;
    }

    return newConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

monkeyInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== `/api/v1/identity/login` && err.response) {
      const tNumber = JSON.parse(localStorage.getItem('monkey-token-number'));

      // Access token has expired and we're refreshing token once
      if (
        err.response.status === 401 &&
        !originalConfig._retry &&
        tNumber !== 2 &&
        MonkeyTokenService.getLocalAccessToken()
      ) {
        try {
          const rs = await monkeyInstance.post(`/api/v1/identity/refresh`, {
            token: MonkeyTokenService.getLocalAccessToken(),
            refreshToken: MonkeyTokenService.getLocalRefreshToken(),
          });

          // New token
          MonkeyTokenService.setToken(rs.data, 2);
          return await monkeyInstance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);

export default monkeyInstance;
