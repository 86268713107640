/* eslint-disable react/prop-types */
import HoverableText from '../../../../Components/HoverableText/HoverableText';
import './Module.scss';

export default function Module({ children }) {
  return <div className="module-wrapper">{children}</div>;
}

Module.KeyFigureSection = function KeyFigureSection({ children }) {
  return <div className="key-figures-section">{children}</div>;
};

Module.KeyFigureSectionAccounts = function KeyFigureSectionAccounts({
  children,
}) {
  return <div className="key-figures-section accounts-module">{children}</div>;
};

Module.KeyFigure = function KeyFigure({
  title,
  value,
  valueType,
  currency,
  subTitle,
  info,
}) {
  let formattedValue = '';
  let formattedSign = '';

  if (valueType === 'currency') {
    formattedValue = Number(Number(value).toFixed(0))
      .toLocaleString()
      .replaceAll(',', ' ');
    formattedSign = currency ?? 'kr';
  } else if (valueType === 'percentage') {
    formattedValue = Number(value)
      .toFixed(0)
      .toLocaleString()
      .replaceAll(',', ' ');
    formattedSign = '%';
  } else {
    formattedValue = value;
    formattedSign = valueType;
  }

  return (
    <div className="key-figure-container">
      <div className="key-figure-content">
        <HoverableText styleClass="key-figure-title" text={title} info={info} />
        <div className="key-figure-value">
          <p className="value">{formattedValue}</p>
          <p className="value-sign">{formattedSign}</p>
        </div>
        <p className="key-figure-subtitle">{subTitle}</p>
      </div>
    </div>
  );
};

Module.DataSection = function DataSection({ children }) {
  return <div className="data-section">{children}</div>;
};

Module.DataColumn = function DataColumn({ children }) {
  return <div className="data-column">{children}</div>;
};

Module.Nav = function Nav({ children }) {
  return <div className="nav-bar">{children}</div>;
};

Module.TransactionContent = function TransactionContent({ children }) {
  return <div className="transaction-content">{children}</div>;
};

Module.ReportSection = function ReportSection({ vertical, children }) {
  return (
    <div className={vertical ? 'reports-section-vertical' : 'reports-section'}>
      {children}
    </div>
  );
};

export const previewGraphData = [
  {
    id: '0',
    data: [
      { x: 0, y: 5 },
      { x: 1, y: 8 },
      { x: 2, y: 7 },
      { x: 3, y: 12 },
      { x: 4, y: 9 },
      { x: 5, y: 10 },
      { x: 6, y: 18 },
      { x: 7, y: 16 },
      { x: 8, y: 12 },
      { x: 9, y: 14 },
      { x: 10, y: 8 },
      { x: 11, y: 12 },
    ],
  },
  {
    id: '1',
    data: [
      { x: 0, y: 10 },
      { x: 1, y: 12 },
      { x: 2, y: 9 },
      { x: 3, y: 10 },
      { x: 4, y: 6 },
      { x: 5, y: 8 },
      { x: 6, y: 15 },
      { x: 7, y: 10 },
      { x: 8, y: 9 },
      { x: 9, y: 6 },
      { x: 10, y: 10 },
      { x: 11, y: 14 },
    ],
  },
];

export const previewGraphProperties = {
  enableArea: true,
  enablePoints: false,
  enableGridX: false,
  enableGridY: false,
  isInteractive: false,
  lineWidth: '2px',
  axisLeft: null,
  axisBottom: null,
  height: 200,
  margin: { top: 30, right: 0, bottom: 30, left: 0 },
  yScale: { type: 'linear', stacked: false },
  colors: ['#2E5BFF', '#56FD10', '#FFDE59'],
  defs: [
    {
      id: 'previewGradient',
      type: 'linearGradient',
      colors: [
        { offset: 0, color: 'inherit' },
        { offset: 100, color: 'inherit', opacity: 0 },
      ],
    },
  ],
  fill: [{ match: '*', id: 'previewGradient' }],
  /* legends: [
    {
      anchor: 'bottom-left',
      direction: 'column',
      justify: false,
      translateX: 10,
      translateY: 0,
      itemsSpacing: 0,
      itemDirection: 'left-to-right',
      itemWidth: 150,
      itemHeight: 20,
      itemOpacity: 0.75,
      symbolSize: 10,
      symbolShape: 'circle',
      effects: [
        {
          on: 'hover',
          style: {
            itemBackground: 'rgba(0, 0, 0, .03)',
            itemOpacity: 1,
          },
        },
      ],
    },
  ], */
};
