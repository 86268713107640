import {
  faExclamationTriangle,
  faUser,
  faChartPie,
  faLink,
} from '@fortawesome/free-solid-svg-icons';

const categories = [
  {
    id: 0,
    name: 'Koppla upp klienter',
    icon: faLink,
    questions: [
      {
        id: '001',
        title: 'Hur kopplar ett bolag upp sig mot Xpektor?',
        answer:
          'Användaren skapar en länk i Xpektor (”koppla klient med länk”), mejlar denna till kund som därefter kopplar på sitt affärssystem, bank och Skatteverket. Vi rekommenderar att aktivera samtliga delar för att nyttja Xpektors fulla kraft. \nMed det sagt går det bra att endast koppla på enskilda delar, exempelvis affärssystem eller bank. Kunden signerar smidigt och enkelt med mobilt bankid. Kunden kan när som helst återuppta sin registrering och återanvända samma länk om fullständig registrering inte sker vid ett och samma tillfälle. \nÄr du redovisningskonsult och har behörighet kan du själv koppla på kunden till Xpektor.',
      },
      {
        id: '002',
        title: 'Hur lägger man till klienter?',
        answer:
          'Det finns två sätt: \nOm du själv har behörighet (Admin) att koppla på kunden lägger du enkelt själv till en klient (exempelvis om du är redovisningskonsult). I annat fall trycker du på knappen ”koppla klient med länk” och följer några enkla steg. \nVidare är det endast Adminbehörighet som har möjlighet att lägga till klienter.',
      },
      {
        id: '003',
        title:
          'Varför går det inte att koppla på bokföringssystem/affärssystem?',
        answer:
          'Om kunden inte lyckas koppla sitt affärssystem beror det troligtvis på att kunden saknar en så kallad integrationslicens. Kunden får ett felmeddelande när ett fel uppstår under processen samt en hjälpknapp som tar de till en "lathund" som beskriver hur man kan lägga till licensen. För kunden att skapa en integrationslicens och därefter koppla upp sig mot Xpektor går smidigt och tar inte lång tid. Med er hjälp endast några minuter.',
      },
      {
        id: '004',
        title: 'Vilka affärssystem kan man koppla?',
        answer:
          'Idag är det möjligt att koppla Fortnox och Visma till Xpektor vilket motsvarar drygt 740 000 SME bolag. Fler affärssystem är på väg och inom kort räknar vi med en täckning på cirka 99%.',
      },
      {
        id: '005',
        title: 'Behöver filer skickas manuellt till Xpektor?',
        answer:
          'Nej! Till skillnad från andra system som kan behöva att ni skickar och laddar upp filer för tolkning behövs inget sådant med Xpektor. Vi läser in allt via APIer för att skapa en smidig användarupplevelse och enkel onboarding.',
      },
      {
        id: '006',
        title:
          'Hur lång tid tar det att koppla affärssystem, bank, Skatteverket och Bolagsverket mot Xpektor?',
        answer:
          'När kunden klickar på länken och följer stegen tar processen inte mer än några minuter, totalt cirka två-tre. Ofta är banken det steget som kan något längre tid vilket kan vara bra att känna till.',
      },
      {
        id: '007',
        title:
          'Behöver alla delar kopplas mot Xpektor eller kan man välja, exempelvis bara affärssystem eller bank?',
        answer:
          'Det går bra att ”bryta ut” och välja att koppla enskilda delar till Xpektor. Däremot rekommenderar vi att koppla samtliga delar så att en fullständig bild av företaget kan presenteras. Om man endast vill koppla en särskild del väljer kunden den önskade delen och godkänner med mobilt bankid. Om kunden i ett senare skedde önskar koppla på övriga delar går det bra och kan göras med samma länk (om så sker inom 30 dagar, annars behövs en ny länk).',
      },
      {
        id: '008',
        title: 'Hur gör man för att hoppa över ett steg?',
        answer:
          'Vill man exempelvis enbart koppla banken är det bara att hoppa över steg 1 (affärssystemet) och gå vidare till steg 2 (bank).',
      },
      {
        id: '009',
        title: 'Kan kunden välja att endast koppla på Skatteverket?',
        answer:
          'Nej det är inte möjligt. För att kunna läsa Skatteverket behövs att kunden kopplar antingen sitt affärssystem eller bank. Om kunden väljer att varken koppla affärssystem, bank eller Skatteverket kan vi ändå läsa väsentliga regulatoriska delar. För att säkerställa att våra kunder alltid har nytta av Xpektor är det byggt på ett sådant sätt att man enkelt kan hoppa över steg och komma tillbaka senare. \nVill kunden inte gå vidare med några steg alls kan vi ändå skapa insikter, om än inte lika många',
      },
      {
        id: '010',
        title: 'Skatteverket står bara och snurrar, varför blir det så?',
        answer:
          'Det kan vara så att din browser ser Skatteverket som ett popup fönster som blockeras. \nHär kan du läsa om hur du tillåter popups: https://support.apple.com/sv-se/guide/safari/sfri40696/mac',
      },
      {
        id: '011',
        title: 'Hur många banker kan Xpektor läsa?',
        answer:
          'Xpektor läser drygt 2600 banker och täcker därmed de flesta banker i Europa.',
      },
      {
        id: '012',
        title: 'Hur ofta uppdateras informationen som finns i Xpektor?',
        answer:
          'Xpektor uppdateras var 15e minut. Således har ni alltid helt färska siffror och information att tillgå.',
      },
      {
        id: '013',
        title: 'Hur lång tid tar det innan jag ser kunden/klienten i Xpektor?',
        answer:
          'Efter det att kunden godkänt alla steg tar det ungefär 5 minuter för informationen att laddas upp i Xpektor. Därefter sker löpande uppdateringar var 15:e minut.',
      },
      {
        id: '014',
        title: "Varför får jag upp 'Finns ingen data för Eget kapital'?",
        answer:
          'Detta kan bero på att en rapport håller på att skapas men ännu inte är färdigställd. Avvakta några minuter så är den snart på plats! Om meddelandet kvarstår kan det bero på att den som har signerat saknar rätt behörighet. \nVår rekommendation är att ni kontaktar kunden för att få detta verifierat, i nästa steg hjälper vi er att bryta kopplingen mot affärssystemet så att ett nytt försök kan göras.',
      },
      {
        id: '015',
        title:
          'Hur länge har Xpektor tillgång till affärssystem, bank och Skatteverket?',
        answer:
          'Så till vida att du eller kunden inte väljer att bryta kopplingen så gäller följande:  Affärssystem -  tillsvidare. \nBank, enligt psd2 direktivet - maximalt 180 dagar och samma gäller för Skatteverket. Således behövs ett nytt godkännande från kund efter 180 dagar om fortsatt insyn önskas hos banken och/eller Skatteverket.',
      },
      {
        id: '016',
        title: 'Går det att bryta kopplingen till Xpektor?',
        answer:
          'Självklart! Är det så att ni eller kunden inte längre önskar att Xpektor hämtar information går bra att koppla bort. Antingen går kunden själv in hos respektive part eller så gör ni det i Xpektor enligt nedan steg. \nVälj Admin 🡪 Klienter 🡪 Tryck på papperskorg-ikon',
      },
      {
        id: '017',
        title: 'Lagrar Xpektor min data?',
        answer:
          'Nej. Xpektor lagrar ingen data utan uppdaterar endast befintlig information som finns tillgänglig via affärssystem, bank, Skatteverket och Bolagsverket.',
      },
      {
        id: '018',
        title: 'Kan man återanvända den länk som skickas till kunden?',
        answer:
          'Svar ja! Det går bra att skicka ut samma länk på nytt till kunden eller att kunden återanvänder samma länk. Exempelvis om kunden har kopplat på sitt affärssystem och sedan återkommer för att koppla på banken och Skatteverket kan allt detta göras med samma länk. Kunden kommer då automatiskt till sitt senaste steg. \nObs! en befintlig länk kan endast användas av den som fått länken sedan tidigare. Det går alltså inte att skicka en befintlig länk till flera kunder.',
      },
      {
        id: '019',
        title:
          'Kunden har råkat koppla sitt privata bankkonto istället för företagets, vad gör jag?',
        answer:
          'I händelse av att kunden valt att koppla sitt privata bankkonto så behövs en ny länk skickas ut',
      },
      {
        id: '020',
        title: 'Hur länge är en länk giltig?',
        answer: 'En länk är giltig i 30 dagar.',
      },
      {
        id: '021',
        title:
          'Finns det någon begränsning i antal företag som Xpektor kan hantera?',
        answer:
          'Nej! Däremot styr licensen antal kunder. Om ni exempelvis använder Xpektor flatrate är det obegränsat hur många kunder som kan hanteras.',
      },
      {
        id: '022',
        title: 'Om vi är flera firmatecknare, hur gör vi då?',
        answer:
          'Eftersom Xpektor endast inhämtar information behövs inget formellt gemensamt godkännande. Därmed räcker det med att den person som har tillgång till affärssystem, bank och Skatteverket godkänner med mobilt bankid. Skulle det vara olika personer kan man enkelt skicka vidare länken så att ”rätt” person kan godkänna.',
      },
    ],
  },
  {
    id: 1,
    name: 'Admin',
    icon: faUser,
    questions: [
      {
        id: '101',
        title: 'Vad är skillnaden mellan olika behörigheter?',
        answer:
          'I Xpektor så finns det två typer av behörigheter, Admin och Användare. Som användare är man något mer begränsad, inte vad gäller möjlighet titta, analysera information eller få notifikationer utan snarare administrativt så som att dela behörigheter och skapa länkar.',
      },
      {
        id: '102',
        title: 'Hur ändrar jag behörighet?',
        answer:
          'Kontakta oss eller den person som är ansvarig i er organisation.',
      },
      {
        id: '103',
        title:
          'Ser jag alla kunder som valt att koppla på Xpektor eller endast mina egna?',
        answer:
          'Alla kunder som din organisation har kopplat till Xpektor ser du.',
      },
      {
        id: '104',
        title: 'Hur lägger man till användare?',
        answer: 'Välj Admin 🡪 Användare 🡪 lägg till användare',
      },
      {
        id: '105',
        title: 'Kan jag ta bort en kund?',
        answer:
          'Självklart! Om du har Admin behörighet följer du enkelt dessa steg: \nAdmin 🡪 Klienter 🡪 tryck på papperskorg-ikonen',
      },
    ],
  },
  {
    id: 2,
    name: 'Varningar och Notiser',
    icon: faExclamationTriangle,
    questions: [
      {
        id: '201',
        title: 'Hur många varningar / notiser har Xpektor?',
        answer: 'I Xpektor så visas fler än 200 varningar.',
      },
      {
        id: '202',
        title: 'Hur ofta får vi varningar / notiser?',
        answer:
          'Så fort någonting händer som triggar en varning i Xpektor blir ni notifierade via mejl. Hur ofta och när dessa notifikationer skall utgå skräddarsys enligt era önskemål. Exempelvis en gång per dag, morgon eller kväll.',
      },
      {
        id: '203',
        title: 'Vart syns varningarna / notiserna?',
        answer:
          'Ni blir informerade via mejl och när ni är inloggade i Xpektor.',
      },
      {
        id: '204',
        title: 'Ger Xpektor tips och råd?',
        answer:
          'Nej. Xpektor samlar in, tolkar information och utför varningar och notiser. Det Xpektor tillhandahåller och presenterar skall fungera som ett kraftfullt stöd för er att initiera en dialog med kunden. Xpektors score kan ses som en guide och fingervisning. Med Xpektor har ni förutsättningar att ställa en rad frågor till kunden för att därefter fatta beslut.',
      },
      {
        id: '205',
        title: 'Visar Xpektor bedrägerier och andra oegentligheter?',
        answer:
          'Xpektor varnar tydligt om vi ser sådana indikationer. Med hjälp av dessa indikationer kan ni sedan fatta ett beslut huruvida ni vill inleda en affärsmässig relation eller inte.',
      },
      {
        id: '206',
        title:
          'Går det att mata in egna siffror, exempelvis om företaget vill ta ett lån, för att se hur det skulle påverka?',
        answer:
          'Inom kort kommer det vara möjligt att göra så kallade moduleringar vilket innebär att ett antal fält blir tillgängliga för användaren. Genom att använda fälten och fylla i egna belopp får användaren ett bra verktyg för att modulera olika scenarion och händelser. Moduleringen visar grafer och hjälper er visualisera vad kan komma att hända.Vi hoppas ha detta på plats inom kort!',
      },
      /* {
        id: '207',
        title:
          'Blir vi notifierade om kunden bryter sin koppling till Xpektor?',
        answer:
          'Ja, om kunden väljer att bryta sin koppling till Xpektor kommer ni att bli notifierade via mejl.',
      }, */
      {
        id: '208',
        title:
          'Behöver man logga in i Xpektor för att se statusar och varningar?',
        answer:
          'Nej! Ni blir notifierade via mejl samt att alla varningar och notifikationer samlas i Xpektor.',
      },
    ],
  },
  {
    id: 3,
    name: 'Score',
    icon: faChartPie,
    questions: [
      {
        id: '301',
        title: 'Vad visar Xpektors score?',
        answer:
          'Xpektor Score kommer att mäta följande sex vertikaler: \n\n1. Bolagets ekonomiska prestation \n2. Bolagets regulatoriska efterlevnad \n3. Bolagets förvaltningsförmåga \n4. Bolagets ej bokförda poster och hur de vägs \n5. Bolagets kontoanalys \n6.Indikationer på bedrägerier',
      },
      {
        id: '302',
        title: 'Hur har ni byggt det?',
        answer:
          'Xpektor Score är noggrant genomarbetat och bygger på vår egen kunskap med stöd av ledande personer inom bedrägeriprevention.',
      },
      {
        id: '303',
        title: 'Hur trovärdigt är ert Score?',
        answer:
          'Det är för tillfället i beta och borde därför inte användas som ett konkret underlag för kritiska beslut. Det kan dock användas som ett hjälpmedel och är särskilt användbart för att få en överblick över alla eventuella brister i ett bolag.',
        // 'Trovärdigt! Scorets alla delar är noga sammanvägda för att ge både en tydlig översiktsbild över företaget och vart eventuella brister finns. Scoret är framtaget på ett sådant sätt att man i detalj kan studera de olika komponenterna som bidrar till ett bolags score. Det vill säga en mer granulerad vy för respektive vertikal finns tillgänglig. Xpektors score är utvecklat tillsammans med de främsta inom bedrägeriprevention och uppdateras löpande för att alltid vara aktuellt.',
      },
      {
        id: '304',
        title:
          'Har ni ett och samma Score för alla bolag och verksamheter eller ändras beroende på bransch?',
        answer:
          'Xpektor Score är uppbyggt på ett och samma sätt. Vi samkör och korsmäter olika vertikaler för att få fram ett score som sedan redovisas. Då branscher kan se olika ut kommer scoret att anpassas utefter behov. Med det sagt är vårt Score ett bra verktyg för att få en helhetsbild och indikation över hur företaget mår, risk för bedrägerier och mer därtill.',
      },
    ],
  },
];
export default categories;
