/* eslint-disable react/prop-types */
import './XpektorModal.scss';

export default function XpektorModal({ children }) {
  return (
    <div className="xpektor-modal">
      <div className="modal-content-window">{children}</div>
    </div>
  );
}

XpektorModal.Header = function Header({ title }) {
  return (
    <div className="xpektor-modal-header">
      <p>{title}</p>
    </div>
  );
};

XpektorModal.Body = function Body({ children }) {
  return <div className="xpektor-modal-body">{children}</div>;
};
