import './Why.scss';
import { Row } from 'react-bootstrap';
import IntroImage from '../../../Images/LandingSpot/xpektor_moln_ny.png';
import Timer from '../../../Images/Why/timer.png';
import Handshake from '../../../Images/Why/handshake.png';
import SKVIcon from '../../../Images/Why/SKV_icon.png';
import AccountingIcon from '../../../Images/Why/Accounting_icon.png';
import BankIcon from '../../../Images/Why/Bank_icon.png';
import LandingNavigation from '../LandingNavigation/LandingNavigation';
import Contact from '../../../Components/Contact/Contact';

export default function Why() {
  return (
    <div className="why-wrapper">
      <Row className="home-page-intro">
        <LandingNavigation />
        <div className="home-intro-content">
          <div className="home-intro-column">
            <h1 className="home-content-title">Varför Xpektor</h1>
            <p>
              För kredit och långivare är det viktigt att få en så tydlig bild
              som möjligt över ett företags ekonomiska läge innan eller i
              samband med att en affärsmässig förbindelse inleds. Här kommer
              Xpektor in i bilden.
              <br />
              <br />
              Att förlita sig på gammal (historisk) data vid kreditbeslut ger
              inte bara en begränsad bild av företaget utan kan också innebära
              att företag inte beviljas lån, exempelvis om man kommer från ett
              tufft ekonomiskt år. Med hjälp av Xpektor får fler möjlighet till
              finansiering eftersom Xpektor analyserar nuläget i ett företag och
              inte det som har varit.
            </p>
          </div>
          <div className="home-intro-column intro-image-column">
            <img
              src={IntroImage}
              alt="Intro alt"
              className="home-intro-image"
            />
          </div>
        </div>
      </Row>
      <div className="why-row">
        <div className="why-row-text-column">
          <h2>Realtidsinformation viktigare än någonsin</h2>
          <p>
            I en tid av skenande bedrägerier och ekonomisk brottslighet gör lån
            och kreditgivare allt för att hantera riskmoment. Därför är det i
            princip ingen som beviljar lån eller krediter på enbart historisk
            information. Branschpraxis är numera att ha så uppdaterade siffror
            och information som möjligt.
          </p>
        </div>
        <img src={Timer} alt="Timer" className="why-row-image" />
      </div>
      <div className="why-row">
        <img src={Handshake} alt="Handshake" className="why-row-image" />
        <div className="why-row-text-column">
          <h2>Med Xpektor sparar alla tid</h2>
          <p>
            I Xpektor visas en tydlig bild av företaget vilket innebär att lån
            och kreditgivaren kan fokusera på att handlägga ärendet. Den som
            ansöker om kredit eller företagslån behöver inte fundera på om rätt
            information delas utan kan känna sig trygg med att Xpektor samlar in
            det underlag som behövs.
          </p>
        </div>
      </div>
      <div className="why-connections-row">
        <div className="why-connection-column">
          <img
            src={AccountingIcon}
            alt="Accounting"
            className="why-column-image"
          />
          <h2>Varför bokföring</h2>
          <p>
            Genom att koppla sin bokföring till Xpektor skapas vad som liknar en
            dagsfärsk årsredovisning. Därmed får handläggaren en klar bild över
            företagets ekonomiska situation. Xpektor läser
            <b>
              <i> inte </i>
            </b>
            bokföring på transaktionsnivå och Xpektor ser
            <b>
              <i> inte </i>
            </b>
            varje enskild transaktion, något som kan vara känsligt. Istället
            summerar Xpektor helheten och kan utläsa väsentlig information
            utifrån det.
          </p>
        </div>
        <div className="why-connection-column">
          <img src={BankIcon} alt="Accounting" className="why-column-image" />
          <h2>Varför företagskonto</h2>
          <p>
            Att få förståelse för vad som händer på företagskontot är viktigt.
            Betalas pengar till högriskländer? Sker betalningar till
            kronofogden? Detta är bara exempel på frågor som långivaren vill och
            behöver förstå innan en affärsmässig förbindelse kan inledas.
          </p>
        </div>
        <div className="why-connection-column">
          <img src={SKVIcon} alt="Accounting" className="why-column-image" />
          <h2>Varför Skatteverket</h2>
          <p>
            Ett allt vanligare sätt att utföra ekonomisk brottslighet är via
            skattekontot (Skatteverket) varför en långivare vill få en inblick.
            Det är också viktigt att säkerställa att den som söker lån eller
            kredit är registrerad på ett korrekt sätt. Exempelvis för F-skatt
            och i arbetsgivarregistret.
          </p>
        </div>
      </div>
      <footer>
        <Contact />
      </footer>
    </div>
  );
}
