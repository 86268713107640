/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Nav, NavLink } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import XpektorLogo from '../../../Images/Xpektor.png';
import './LandingNavigation.scss';

export default function LandingNavigation() {
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  const handleClose = () => setShowMenu(false);
  const handleShow = () => setShowMenu(true);

  function redirectTo(targetPage) {
    history.push(targetPage);
  }

  return (
    <div>
      <div className="landing-nav-content">
        <div className="landing-nav-logo">
          <img src={XpektorLogo} alt="XpektorLogo" />
          <a href="/">Xpektor</a>
        </div>
        <div className="landing-nav-main">
          <div className="landing-nav-center">
            <NavLink
              className={`text-uppercase text-nowrap mt-5 mt-lg-0 p-lg-4 ${
                window.location.pathname === '/product' ? 'active' : ''
              }`}
              onClick={() => redirectTo('/product')}
              style={{
                color: '#3e66fb',
                fontWeight: '500',
              }}
            >
              Vår produkt
            </NavLink>
            <NavLink
              className={`text-uppercase text-nowrap p-lg-4 ${
                window.location.pathname === '/target' ? 'active' : ''
              }`}
              onClick={() => redirectTo('/target')}
              style={{
                color: '#3e66fb',
                fontWeight: '500',
              }}
            >
              För vem
            </NavLink>
            <NavLink
              className={`text-uppercase text-nowrap p-lg-4 ${
                window.location.pathname === '/about' ? 'active' : ''
              }`}
              onClick={() => redirectTo('/about')}
              style={{
                color: '#3e66fb',
                fontWeight: '500',
              }}
            >
              Om oss
            </NavLink>
            {/* enable this if needed in future
                  <NavLink
                    className="text-uppercase text-nowrap p-lg-4"
                    href="/offers"
                    style={{
                      color:
                        window.location.pathname === '/'
                          ? '#E9E9E9'
                          : '#92979D',
                    }}
                  >
                    Erbjudanden
                  </NavLink>
                  */}
          </div>

          <div className="landing-nav-end">
            <a
              className="p-lg-4"
              href="https://meetings-eu1.hubspot.com/jacob-liebermann?embed=true"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: '#3e66fb',
                fontWeight: '500',
                textDecoration: 'none',
              }}
            >
              BOKA DEMO
            </a>
            <NavLink
              className="text-uppercase p-lg-4"
              onClick={() => redirectTo('/login')}
              style={{
                color: '#3e66fb',
                fontWeight: '500',
              }}
            >
              Logga in
            </NavLink>
          </div>
        </div>
        <FontAwesomeIcon
          icon={faBars}
          onClick={handleShow}
          className="l-nav-menu-icon"
        />
      </div>

      <div className={showMenu ? 'menu_active' : 'nav_container'}>
        <div className="text-end pt-3 pe-4">
          <Button
            className="btn-close"
            bsPrefix="button"
            onClick={handleClose}
          />
        </div>
        <div className="menuItems mt-5">
          <Nav defaultActiveKey={window.location.pathname}>
            <NavLink
              className="text-nowrap w-100"
              onClick={() => redirectTo('/product')}
              style={{ color: '#616569' }}
            >
              Vår produkt
            </NavLink>
            <NavLink
              className="text-nowrap w-100"
              onClick={() => redirectTo('/target')}
              style={{ color: '#616569' }}
            >
              För vem
            </NavLink>
            <NavLink
              className="text-nowrap w-100"
              onClick={() => redirectTo('/about')}
              style={{ color: '#616569' }}
            >
              Om oss
            </NavLink>
            {/* enable this if needed in future
            <NavLink
              className="text-nowrap w-100"
              href="/offers"
              style={{ color: '#616569' }}
            >
              Erbjudanden
            </NavLink>
            */}
            <NavLink>
              <h5
                className="loginH5 w-100 mt-4 text-nowrap"
                onClick={() => redirectTo('/login')}
              >
                Logga in
              </h5>
            </NavLink>
          </Nav>
        </div>
      </div>
    </div>
  );
}
