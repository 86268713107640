import isEmail from 'validator/lib/isEmail';

export default function validateSettingsForm(
  formNumber,
  validationSet,
  values
) {
  const errors = {};

  if (formNumber === 1) {
    switch (validationSet) {
      case 1:
        if (values.length !== 10) {
          errors.newOrgNumber = 'Fel organisationsnummer';
          return errors;
        }
        break;
      case 2:
        if (values === '') {
          errors.newCustomerName = 'Företagsnamn kan inte va tomt';
          return errors;
        }
        break;
      case 3:
        break;
      case 4:
        if (values === '') {
          errors.newBillingEmail = 'Skriv in faktureringsadress';
          return errors;
        }
        if (!isEmail(values)) {
          errors.newBillingEmail = 'Fel i mejladress';
          return errors;
        }
        break;
      case 5:
        if (values.length !== 9) {
          errors.newPhoneNumber = 'Fel i telefonnummer';
          return errors;
        }
        break;
      default:
        return errors;
    }
  }

  if (formNumber === 2) {
    switch (validationSet) {
      case 1:
        if (values === '') {
          errors.newClientName = 'Klientnamn tomt';
          return errors;
        }
        break;
      case 2:
        if (values.toString().length !== 10) {
          errors.newOrgNumber = 'Fel organisationsnummer';
          return errors;
        }
        break;
      case 3:
        break;
      case 4:
        if (values === '') {
          errors.newShareCapital = 'Fyll i aktiekapital';
          return errors;
        }
        break;
      default:
        return errors;
    }
  }

  if (formNumber === 3) {
    switch (validationSet) {
      case 1:
        if (values === '') {
          errors.newUserName = 'Namn kan inte va tomt';
          return errors;
        }
        break;
      case 2:
        if (values === '') {
          errors.newEmail = 'Mejl kan inte va tomt';
          return errors;
        }
        if (!isEmail(values)) {
          errors.newEmail = 'Fel i mejladress';
          return errors;
        }
        break;
      case 3:
        if (values === '') {
          errors.newPw = 'Lösenord kan ej vara tomt';
          return errors;
        }
        if (values.length < 8) {
          errors.newPw = 'Lösenord måste ha minst 8 tecken';
          return errors;
        }
        break;
      default:
        return errors;
    }
  }

  return errors;
}
