import { Route, Switch } from 'react-router-dom';
import Login from './Pages/Home/Login/Login';
import NotFound from './Components/NotFound/NotFound';
import Dashboard from './Pages/WebApp/Dashboard/Dashboard';
import CompanyInfo from './Pages/WebApp/CompanyInfo/CompanyInfo';
import ChangePassword from './Pages/WebApp/ChangePassword/ChangePassword';
import AdminSettings from './Pages/WebApp/Settings/AdminSettings';
import SystemAdmin from './Pages/WebApp/SystemAdmin/SystemAdmin';
import Faq from './Pages/WebApp/FAQ/Faq';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/dashboard">
        <Dashboard />
      </Route>
      <Route exact path="/companyinfo/:clientId">
        <CompanyInfo />
      </Route>
      <Route exact path="/settings/:tab">
        <AdminSettings />
      </Route>
      <Route exact path="/systemadmin/:systemtab">
        <SystemAdmin />
      </Route>
      <Route extact path="/FAQ">
        <Faq />
      </Route>
      <Route exact path="/changePassword">
        <ChangePassword />
      </Route>
      {/* Routes to be added later regarding settings:
				<Route exact path="/settings/modules">
					<ModuleSettings />
				</Route>
				<Route exact path="/settings/logg">
					<LoggSettings />
				</Route>
			*/}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
