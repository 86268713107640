/* eslint-disable import/no-unresolved */
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Autoplay, Navigation, Pagination, Scrollbar } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Container, Image, Row } from 'react-bootstrap';
import QuoteMark from '../../../Images/Quote.svg';

export default function QuoteSwiper() {
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
      spaceBetween={5}
      slidesPerView={1}
      pagination={{
        clickable: true,
        color: '',
      }}
      centeredSlides
      centerInsufficientSlides
      loop
      loopFillGroupWithBlank
      autoplay={{
        delay: 3500,
        disableOnInteraction: true,
      }}
    >
      <SwiperSlide className="swiper-slide-active align-items-center">
        <Container className="carouselContent py-5 px-5 mx-3">
          <Row>
            <div className="col-10">
              <p>
                Xpektor är en utmärkt produkt där du kan se bolagets egna
                kapital tillsammans med det ackumulerade resultatet i realtid.
                På så sätt får du en totalöverblick av bolagets ekonomiska
                ställning utan att behöva analysera rapporter. Med Xpektor kan
                vi effektivisera och ge rådgivning till rätt klient, vid rätt
                tillfälle.
              </p>
              <p className="text-center" style={{ fontStyle: 'italic' }}>
                Daniel Fagerberg, Verksamhetschef
              </p>
            </div>
            <div className="col-2">
              <Image fluid src={QuoteMark} />
            </div>
          </Row>
        </Container>
      </SwiperSlide>
      <SwiperSlide>
        <Container className="carouselContent py-5 px-5 mx-3">
          <Row>
            <div className="col-10">
              <p>
                Att driva en startup med stora utvecklingskostnader innan
                försäljningen tar fart medför flera utmaningar. Med Xpektor får
                vi enkelt översikt över det egna kapitalet och får snabbt koll.{' '}
              </p>
              <p className="text-center" style={{ fontStyle: 'italic' }}>
                Elina Åkerlind, Rådgivare
              </p>
            </div>
            <div className="col-2">
              <Image fluid src={QuoteMark} />
            </div>
          </Row>
        </Container>
      </SwiperSlide>
      <SwiperSlide>
        <Container className="carouselContent py-5 px-5 mx-3">
          <Row>
            <div className="col-10">
              <p>
                Xpektor är ett bra komplement för att bevaka risken för obestånd
                och brister i förvaltningen i en tid där definitionen av ett
                aktiebolag urholkas med bl.a. minskat krav på aktiekapital och
                slopad revisionsplikt.{' '}
              </p>
              <p className="text-center" style={{ fontStyle: 'italic' }}>
                Sebastien Argillet, Auktoriserad revisor{' '}
              </p>
            </div>
            <div className="col-2">
              <Image fluid src={QuoteMark} />
            </div>
          </Row>
        </Container>
      </SwiperSlide>
      <SwiperSlide>
        <Container className="carouselContent py-5 px-5 mx-3">
          <Row>
            <div className="col-10">
              <p>En enkel lösning på ett stort problem.</p>
              <p className="text-center" style={{ fontStyle: 'italic' }}>
                Linda Andersson, Ekonomisk rådgivare
              </p>
            </div>
            <div className="col-2">
              <Image fluid src={QuoteMark} />
            </div>
          </Row>
        </Container>
      </SwiperSlide>
      <SwiperSlide>
        <Container className="carouselContent py-5 px-5 mx-3">
          <Row>
            <div className="col-10">
              <p>
                Med Xpektor sparar jag 1 timme per dag. Alternativet är att ta
                fram datan manuellt från affärssystemet regelbundet och det har
                jag inte tid med.
              </p>
              <p className="text-center" style={{ fontStyle: 'italic' }}>
                Anna-Ida Apelqvist, Redovisningskonsult
              </p>
            </div>
            <div className="col-2">
              <Image fluid src={QuoteMark} />
            </div>
          </Row>
        </Container>
      </SwiperSlide>
      <SwiperSlide>
        <Container className="carouselContent py-5 px-5">
          <Row>
            <div className="col-10">
              <p>
                Att få en notis så fort skattekontot får ett negativt saldo gör
                att jag känner mig tryggare i min roll som styrelseledamot. Jag
                behöver inte längre be om skattekontoutdrag för att veta status.
              </p>
              <p className="text-center" style={{ fontStyle: 'italic' }}>
                Sten Liljedahl, Styrelseledamot
              </p>
            </div>
            <div className="col-2">
              <Image fluid src={QuoteMark} />
            </div>
          </Row>
        </Container>
      </SwiperSlide>
    </Swiper>
  );
}
