/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-alert */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import './BankConnectionModal.scss';
import { Button, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Moment from 'moment';
import BankService from '../../../Services/BankService';
import LoadingBar from '../../../Components/LoadingBar/LoadingBar';

function BankConnectionModal({ handleClose, client }) {
  const [session, setSession] = useState(null);

  useEffect(() => {
    const getSession = async () => {
      await BankService.getClientBankSession(client.id)
        .then((response) => {
          setSession(response.data);
        })
        .catch(() => {});
    };

    if (Object.keys(client).length !== 0) {
      getSession();
    }
  }, [client]);

  return (
    <Modal
      show
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="border-0 bank-modal-wrapper"
    >
      <Modal.Header>
        <Modal.Title>{`Bankkoppling för ${client.name}`}</Modal.Title>
        <Button className="btn-close" bsPrefix="button" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body className="border-0">
        {(client.bankConnectionActive || client.hadBankConnection) && (
          <div className="bank-connected-view">
            {session === null ? (
              <LoadingBar active status="Hämtar..." relative />
            ) : (
              <div
                className={`bank-session-content ${
                  session.status === 'AUTHORIZED'
                    ? 'session-ok'
                    : 'session-error'
                }`}
              >
                <div className="bank-session-status">
                  <p>{session.status}</p>
                </div>
                <div className="bank-session-details">
                  <p>
                    <b>Bank: </b>
                    {session.bankName}
                  </p>
                  <p>
                    <b>Landskod: </b>
                    {session.bankCountry}
                  </p>
                  <p>
                    <b>Koppling giltig t.om: </b>
                    {Moment.utc(session.validUntil)
                      .local()
                      .format('YYYY-MM-DD HH:mm')}
                  </p>
                  {session.status !== 'AUTHORIZED' &&
                    new Date(session.validUntil) >
                      new Date(Moment.utc().local()) && (
                      <p className="session-error-text">
                        Kopplingen är ej giltig trots att det sista giltiga
                        datumet ligger i framtiden. Detta kan t.ex ske om det
                        finns störningar hos banken, om klienten har kopplat
                        banken i en annan applikation, eller då vissa banker
                        inte tillåter att samma person autentiserar bank för mer
                        än en klient.
                      </p>
                    )}
                </div>
              </div>
            )}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default BankConnectionModal;
