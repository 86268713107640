/* eslint-disable react/prop-types */
import './OnboardingIconButton.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function OnboardingIconButton({
  onClick,
  icon,
  iconPosition,
  content,
}) {
  return (
    <div className="onboarding-icon-button">
      <button
        className="onboarding-icon-button-content"
        type="button"
        onClick={onClick}
      >
        {iconPosition === 'left' && (
          <span className="left-arrow-button">
            <FontAwesomeIcon className="left-arrow icon" icon={icon} />
            {content}
          </span>
        )}
        {iconPosition === 'right' && (
          <span className="right-arrow-button">
            {content}
            <FontAwesomeIcon className="right-arrow icon" icon={icon} />
          </span>
        )}
      </button>
    </div>
  );
}
