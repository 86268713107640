import { useState, useEffect } from 'react';
import './AuthLinkSettings.scss';
import AuthLinkTable from './AuthLinkTable';
import CreateAuthLinkModal from '../../Modals/CreateAuthLinkModal';
import AuthLinkModal from './AuthLinkModal';
import XpektorButton from '../../../../Components/XpektorButton/XpektorButton';
import AuthService from '../../../../Services/AuthService';

export default function AuthLinkSettings() {
  const [authLinks, setAuthLinks] = useState([]);
  const [selectedAuthLink, setSelectedAuthLink] = useState();
  const [showAuthLinkModal, setShowAuthLinkModal] = useState(false);
  const [showCreateAuthLinkModal, setShowCreateAuthLinkModal] = useState(false);

  const fetchExternalStates = async () => {
    await AuthService.getAllExternalStates()
      .then((response) => {
        setAuthLinks(response.data);
        // updateStats(response.data);
      })
      .catch(() => {
        setAuthLinks([]);
        // resetStats();
      });
  };

  const [order, setOrder] = useState('asc');

  const sortStringCompare = (a, b) => {
    const stringA = a?.toUpperCase() ?? '';
    const stringB = b?.toUpperCase() ?? '';

    if (stringA < stringB) {
      return -1;
    }
    if (stringA > stringB) {
      return 1;
    }
    return 0;
  };

  const sortByName = () => {
    if (order === 'asc') {
      authLinks.sort((a, b) => {
        return sortStringCompare(a.targetClient, b.targetClient);
      });
      setOrder('desc');
    } else if (order === 'desc') {
      authLinks.sort((a, b) => {
        return sortStringCompare(a.targetClient, b.targetClient) * -1;
      });
      setOrder('asc');
    }
  };

  const sortByUserName = () => {
    if (order === 'asc') {
      authLinks.sort((a, b) => {
        return sortStringCompare(a.requestingUser, b.requestingUser);
      });
      setOrder('desc');
    } else if (order === 'desc') {
      authLinks.sort((a, b) => {
        return sortStringCompare(a.requestingUser, b.requestingUser) * -1;
      });
      setOrder('asc');
    }
  };

  const dateCompare = (a, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);

    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  };
  const sortByDateCreated = () => {
    if (order === 'asc') {
      authLinks.sort((a, b) => {
        return dateCompare(a.dateCreated, b.dateCreated);
      });
      setOrder('desc');
    } else if (order === 'desc') {
      authLinks.sort((a, b) => {
        return dateCompare(a.dateCreated, b.dateCreated) * -1;
      });
      setOrder('asc');
    }
  };

  const sortByDateActivity = () => {
    if (order === 'asc') {
      authLinks.sort((a, b) => {
        return dateCompare(a.lastAccessed, b.lastAccessed);
      });
      setOrder('desc');
    } else if (order === 'desc') {
      authLinks.sort((a, b) => {
        return dateCompare(a.lastAccessed, b.lastAccessed) * -1;
      });
      setOrder('asc');
    }
  };

  const statusCompare = (a, b) => {
    const statusA = a ? 0 : 1;
    const statusB = b ? 0 : 1;
    if (statusA < statusB) {
      return -1;
    }
    if (statusA > statusB) {
      return 1;
    }
    return 0;
  };

  const sortByAccountingStatus = () => {
    if (order === 'asc') {
      authLinks.sort((a, b) => {
        return statusCompare(a.accountingConnected, b.accountingConnected);
      });
      setOrder('desc');
    } else if (order === 'desc') {
      authLinks.sort((a, b) => {
        return statusCompare(a.accountingConnected, b.accountingConnected) * -1;
      });
      setOrder('asc');
    }
  };

  const sortByBankStatus = () => {
    if (order === 'asc') {
      authLinks.sort((a, b) => {
        return statusCompare(a.bankConnected, b.bankConnected);
      });
      setOrder('desc');
    } else if (order === 'desc') {
      authLinks.sort((a, b) => {
        return statusCompare(a.bankConnected, b.bankConnected) * -1;
      });
      setOrder('asc');
    }
  };

  const sortBySkvStatus = () => {
    if (order === 'asc') {
      authLinks.sort((a, b) => {
        return statusCompare(a.skvConnected, b.skvConnected);
      });
      setOrder('desc');
    } else if (order === 'desc') {
      authLinks.sort((a, b) => {
        return statusCompare(a.skvConnected, b.skvConnected) * -1;
      });
      setOrder('asc');
    }
  };

  useEffect(() => {
    const getData = async () => {
      await AuthService.getAllExternalStates()
        .then((response) => {
          setAuthLinks(response.data);
          // updateStats(response.data);
        })
        .catch(() => {
          setAuthLinks([]);
        });
    };

    getData();
  }, []);

  /*  const sortedAuthLinks = authLinks.sort(
    (a, b) => new Date(b.dateCreated) - new Date(a.dateCreated)
  ); */

  return (
    <div className="auth-link-settings-container">
      <div className="auth-link-settings-nav">
        {/* <div className="auth-link-stats">
          <p>{`Länkar klickade: ${linksClickedCount}/${authLinks.length}`}</p>
          <p>{`Bokföring kopplad: ${accountingCompletedCount}/${authLinks.length}`}</p>
          <p>{`Bank kopplad: ${bankCompletedCount}/${authLinks.length}`}</p>
          <p>{`Skv kopplat: ${skvCompletedCount}/${authLinks.length}`}</p>
        </div> */}
        <div />
        <XpektorButton
          content="Skapa länk"
          onClick={() => {
            setShowCreateAuthLinkModal(true);
          }}
        />
      </div>
      <AuthLinkTable
        sortByName={sortByName}
        sortByDateActivity={sortByDateActivity}
        sortByAccountingStatus={sortByAccountingStatus}
        sortByBankStatus={sortByBankStatus}
        sortBySkvStatus={sortBySkvStatus}
        sortByUserName={sortByUserName}
        sortByDateCreated={sortByDateCreated}
        order={order}
      >
        {authLinks.map((data) => {
          return (
            <AuthLinkTable.AuthLink
              authState={data}
              onClick={() => {
                setSelectedAuthLink(data);
                setShowAuthLinkModal(true);
              }}
              key={data.id}
            />
          );
        })}
      </AuthLinkTable>
      {showCreateAuthLinkModal && (
        <CreateAuthLinkModal
          handleClose={() => {
            setShowCreateAuthLinkModal(false);
          }}
          handleNewLink={() => {
            fetchExternalStates();
          }}
        />
      )}
      {showAuthLinkModal && (
        <AuthLinkModal
          authLink={selectedAuthLink}
          handleClose={() => setShowAuthLinkModal(false)}
          handleEdit={() => fetchExternalStates()}
        />
      )}
    </div>
  );
}
