import './TransactionDetails.scss';
import Moment from 'moment';
import { Button, Modal } from 'react-bootstrap';
import LabeledInfo from '../../../../../../Components/LabeledInfo/LabeledInfo';

/* eslint-disable react/prop-types */
export default function TransactionDetails({ transaction, handleClose }) {
  const amountSigned =
    transaction.creditDebitIndicator === 'DBIT'
      ? transaction.transactionAmount.amount * -1
      : transaction.transactionAmount.amount * 1; // to get rid of decimals
  return (
    <Modal
      show
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="border-0 transaction-details-wrapper"
    >
      <Modal.Header>
        <Modal.Title>{transaction.name}</Modal.Title>
        <Button className="btn-close" bsPrefix="button" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <LabeledInfo
          label="Belopp: "
          info={`${amountSigned} ${transaction.transactionAmount.currency}`}
          animatedX
        />
        <LabeledInfo
          label="Valuta datum: "
          info={
            transaction.valueDate
              ? Moment(transaction.valueDate).format('YYYY-MM-DD')
              : 'N/A'
          }
          animatedX
          animDelay={0.1}
        />
        <LabeledInfo
          label="Bokföringsdatum: "
          info={
            transaction.valueDate
              ? Moment(transaction.bookingDate).format('YYYY-MM-DD')
              : 'N/A'
          }
          animatedX
          animDelay={0.2}
        />
        <LabeledInfo
          label="Info: "
          info={transaction.remittanceInformation}
          animatedX
          animDelay={0.3}
        />
        <LabeledInfo
          label="Typ: "
          info={transaction.industries.join(', ')}
          animatedX
          animDelay={0.4}
        />
      </Modal.Body>
    </Modal>
  );
}
