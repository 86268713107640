/* eslint-disable react/prop-types */
import './AuthLinkTable.scss';
import { useState } from 'react';
import Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCircleXmark,
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';

function TableHeaderItem({ label, onClick, orderIcon, columnClass }) {
  return (
    <th className={columnClass ?? ''}>
      <button type="button" className="sortable" onClick={onClick}>
        {label}
      </button>
      <FontAwesomeIcon icon={orderIcon ?? faSort} />
    </th>
  );
}

export default function AuthLinkTable({
  children,
  sortByName,
  sortByDateActivity,
  sortByAccountingStatus,
  sortByBankStatus,
  sortBySkvStatus,
  sortByUserName,
  sortByDateCreated,
  order,
}) {
  const [arrowIcon, setArrowIcon] = useState();
  const [currentOrdered, setCurrentOrdered] = useState('');

  function OnSort() {
    setArrowIcon(order === 'asc' ? faSortUp : faSortDown);
  }
  return (
    <table className="auth-link-table">
      <thead>
        <tr>
          <TableHeaderItem
            label="Klient"
            onClick={() => {
              sortByName();
              OnSort();
              setCurrentOrdered('clientName');
            }}
            orderIcon={currentOrdered === 'clientName' ? arrowIcon : null}
          />
          {/* <th>Klient</th> */}

          <th>Org. nummer</th>
          <TableHeaderItem
            label="Senast klickad"
            onClick={() => {
              sortByDateActivity();
              OnSort();
              setCurrentOrdered('activity');
            }}
            orderIcon={currentOrdered === 'activity' ? arrowIcon : null}
          />
          {/* <th>Senast klickad</th> */}
          <TableHeaderItem
            columnClass="icon-column"
            label="Bokföring"
            onClick={() => {
              sortByAccountingStatus();
              OnSort();
              setCurrentOrdered('accounting');
            }}
            orderIcon={currentOrdered === 'accounting' ? arrowIcon : null}
          />
          {/* <th >Bokföring</th> */}
          <TableHeaderItem
            columnClass="icon-column"
            label="Bank"
            onClick={() => {
              sortByBankStatus();
              OnSort();
              setCurrentOrdered('bank');
            }}
            orderIcon={currentOrdered === 'bank' ? arrowIcon : null}
          />
          {/* <th >Bank</th> */}
          <TableHeaderItem
            columnClass="icon-column"
            label="Skatteverket"
            onClick={() => {
              sortBySkvStatus();
              OnSort();
              setCurrentOrdered('skv');
            }}
            orderIcon={currentOrdered === 'skv' ? arrowIcon : null}
          />
          {/* <th >Skatteverket</th> */}
          <TableHeaderItem
            label="Skapare"
            onClick={() => {
              sortByUserName();
              OnSort();
              setCurrentOrdered('username');
            }}
            orderIcon={currentOrdered === 'username' ? arrowIcon : null}
          />
          {/* <th>Skapare</th> */}
          <TableHeaderItem
            label="Skapad"
            onClick={() => {
              sortByDateCreated();
              OnSort();
              setCurrentOrdered('created');
            }}
            orderIcon={currentOrdered === 'created' ? arrowIcon : null}
          />
          {/* <th>Skapad </th> */}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
}

AuthLinkTable.AuthLink = function AuthLink({ authState, onClick }) {
  const renderStepIcon = (completed) => (
    <td className={`icon-column ${completed ? 'green-icon' : 'red-icon'}`}>
      <FontAwesomeIcon icon={completed ? faCircleCheck : faCircleXmark} />
    </td>
  );
  return (
    <tr onClick={onClick}>
      <td>{authState.clientName ?? authState.phoneNumber ?? 'N/A'}</td>
      <td>{authState.organizationNumber ?? 'N/A'}</td>
      <td>
        {authState.lastAccessed
          ? Moment(authState.lastAccessed).format('YYYY-MM-DD HH:mm')
          : 'N/A'}
      </td>
      {renderStepIcon(authState.accountingConnected === true)}
      {renderStepIcon(authState.bankConnected === true)}
      {renderStepIcon(authState.skvConnected === true)}
      <td>{authState.requestingUserName}</td>
      <td>
        {authState.dateCreated
          ? Moment(authState.dateCreated).format('YYYY-MM-DD HH:mm')
          : 'N/A'}
      </td>
    </tr>
  );
};
