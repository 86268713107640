/* eslint-disable react/prop-types */
import './TabNav.scss';

export default function TabNav({ children }) {
  return (
    <div className="tab-nav-wrapper">
      <div className="tab-nav-content">{children}</div>
    </div>
  );
}

TabNav.Tab = function Tab(props) {
  const { title } = props;
  const { onClick } = props;
  const { active } = props;

  return (
    <button
      className={`${active ? 'active-tab' : ''}`}
      type="button"
      onClick={onClick}
    >
      <p>{title}</p>
    </button>
  );
};
