/* eslint-disable react/prop-types */
import './GeneralSettings.scss';
import { useState, useEffect } from 'react';
import isEmail from 'validator/lib/isEmail';
import EditablePanel from '../../../../Components/EditablePanel/EditablePanel';
import CustomerService from '../../../../Services/CustomerService';
import UserService from '../../../../Services/UserService';

export default function GeneralSettings() {
  const [editCompanyInfo, setEditCompanyInfo] = useState(false);
  const [editResponsible, setEditResponsible] = useState(false);

  const [orgNumberInput, setOrgNumberInput] = useState('');
  const [customerNameInput, setCustomerNameInput] = useState('');
  const [contactPersonInput, setContactPersonInput] = useState('');
  const [billingEmailInput, setBillingEmailInput] = useState('');
  const [phoneNumberInput, setPhoneNumberInput] = useState('');

  const [orgNumberError, setOrgNumberError] = useState('');
  const [customerNameError, setCustomerNameError] = useState('');
  const [billingEmailError, setBillingEmailError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');

  const [customer, setCustomer] = useState({});

  useEffect(() => {
    const getData = async () => {
      const user = await UserService.getCurrentUser();
      await CustomerService.getCustomer(user.customerId)
        .then((response) => {
          setCustomer(response.data);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            alert('Hittar inte kunden');
          }
          if (error.response.status === 500) {
            alert('Något gick fel försök igen om en stund');
          }
        });
    };
    getData();
  }, []);

  const saveCustomer = async (newCustomer) => {
    await CustomerService.updateCustomer(newCustomer)
      .then((response) => {
        if (response) {
          setCustomer(response.data);
          alert('Kund uppdaterad');
          setEditCompanyInfo(false);
          setEditResponsible(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          alert('Hittar inte kunden');
        }
        if (error.response.status === 500) {
          alert('Något gick fel försök igen om en stund');
        }
      });
  };

  const validateAndSaveCompanyInfo = async () => {
    if (
      orgNumberInput !== customer.organizationNumber ||
      customerNameInput !== customer.name
    ) {
      const orgNumberValid = orgNumberInput.length === 10;
      const customerNameValid = customerNameInput !== '';

      setOrgNumberError('');
      setCustomerNameError('');

      if (!orgNumberValid) {
        setOrgNumberError('Ej giltigt org. nummer.');
      }

      if (!customerNameValid) {
        setCustomerNameError('Vänligen fyll i ett företagsnamn.');
      }

      if (!orgNumberValid || !customerNameValid) return;

      const updatedCustomer = { ...customer };
      updatedCustomer.organizationNumber = orgNumberInput;
      updatedCustomer.name = customerNameInput;

      saveCustomer(updatedCustomer);
    }
    setOrgNumberError('');
    setCustomerNameError('');
    setEditCompanyInfo(false);
  };

  const validateAndSaveResponsible = async () => {
    if (
      customerNameInput !== customer.name ||
      billingEmailInput !== customer.billingEmail ||
      phoneNumberInput !== customer.phoneNumber
    ) {
      const emailValid = isEmail(billingEmailInput);
      const phoneNumberValid = phoneNumberInput.length === 10;

      setBillingEmailError('');
      setPhoneNumberError('');

      if (!emailValid) {
        setBillingEmailError('Ej giltig email-adress');
      }

      if (!phoneNumberValid) {
        setPhoneNumberError('Ej giltigt telefonnummer');
      }

      if (!emailValid || !phoneNumberValid) return;

      const updatedCustomer = { ...customer };
      updatedCustomer.contactPerson = contactPersonInput;
      updatedCustomer.billingEmail = billingEmailInput;
      updatedCustomer.phoneNumber = phoneNumberInput;

      saveCustomer(updatedCustomer);
    }
    setBillingEmailError('');
    setPhoneNumberError('');
    setEditResponsible(false);
  };

  return (
    <div className="general-settings-container">
      <EditablePanel
        title="Företagsuppgifter"
        editMode={editCompanyInfo}
        onEditClick={() => {
          setEditCompanyInfo(true);
          setOrgNumberInput(customer.organizationNumber);
          setCustomerNameInput(customer.name);
        }}
        onCancelClick={() => {
          setEditCompanyInfo(false);
          setOrgNumberError('');
          setCustomerNameError('');
        }}
        onConfirmClick={() => {
          validateAndSaveCompanyInfo();
        }}
      >
        <EditablePanel.Entry
          label="Organisationsnummer"
          value={customer.organizationNumber}
          inputValue={orgNumberInput}
          onInputChange={(e) => setOrgNumberInput(e.target.value)}
          editMode={editCompanyInfo}
          infoText={orgNumberError}
        />
        <EditablePanel.Entry
          label="Företagsnamn"
          value={customer.name}
          inputValue={customerNameInput}
          onInputChange={(e) => setCustomerNameInput(e.target.value)}
          editMode={editCompanyInfo}
          infoText={customerNameError}
        />
      </EditablePanel>
      <EditablePanel
        title="Licensansvarig"
        editMode={editResponsible}
        onEditClick={() => {
          setEditResponsible(true);
          setContactPersonInput(customer.contactPerson);
          setBillingEmailInput(customer.billingEmail);
          setPhoneNumberInput(customer.phoneNumber);
        }}
        onCancelClick={() => {
          setEditResponsible(false);
          setBillingEmailError('');
          setPhoneNumberError('');
        }}
        onConfirmClick={() => {
          validateAndSaveResponsible();
        }}
      >
        <EditablePanel.Entry
          label="För & Efternamn"
          value={customer.contactPerson}
          inputValue={contactPersonInput}
          onInputChange={(e) => setContactPersonInput(e.target.value)}
          editMode={editResponsible}
        />
        <EditablePanel.Entry
          label="Faktureringsmail"
          value={customer.billingEmail}
          inputValue={billingEmailInput}
          onInputChange={(e) => setBillingEmailInput(e.target.value)}
          editMode={editResponsible}
          infoText={billingEmailError}
        />
        <EditablePanel.Entry
          label="Telefonnummer"
          value={customer.phoneNumber}
          inputValue={phoneNumberInput}
          onInputChange={(e) => setPhoneNumberInput(e.target.value)}
          editMode={editResponsible}
          infoText={phoneNumberError}
        />
      </EditablePanel>
    </div>
  );
}
