import { Container, Image, ListGroup, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import Contact from '../../../Components/Contact/Contact';
import LandingNavigation from '../LandingNavigation/LandingNavigation';
import './ForWhom.scss';
import PhonePic from '../../../Images/ForWhom/Group 11.png';
import MapImg from '../../../Images/ForWhom/growthPlan.png';
import QuoteSwiper from './QuoteSwiper';

export default function ForWhom() {
  return (
    <div className="h-100">
      <LandingNavigation />
      <div className="mt-5">
        <Row>
          <div className="col-12 col-lg-5">
            <Container className="p-5">
              <h2>För vem</h2>
              <p>
                Xpektor servar kunder inom ett antal segment. Datan Xpektor
                analyserar är värdefull information för bland annat; banker,
                finansinstitut, redovisningskonsulter, revisionsbyråer, kommuner
                och styrelsemedlemmar.
              </p>
              <Image fluid src={MapImg} alt="world-map" />
            </Container>
          </div>
          <div className="col-12 col-lg-7">
            <Container className="p-4 p-lg-5">
              <Row className="mb-5">
                <Container className="col-12 col-lg-3 mt-1">
                  <h6>Redovisnings & Revisionsbyråer</h6>
                </Container>
                <Container className="col-12 col-lg-9">
                  <ListGroup className="listThingy">
                    <ListGroup.Item className="bg-transparent border-0 py-0 py-0">
                      <Row>
                        <div className="col-1 mt-1">
                          <FontAwesomeIcon
                            icon={faChevronCircleRight}
                            className="blueChevron"
                          />
                        </div>
                        <div className="col-11">
                          <p>
                            Kan prioritera kunderna i realtid och fatta snabbare
                            beslut med prediktiva siffror
                          </p>
                        </div>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-transparent border-0 py-0 py-0">
                      <Row>
                        <div className="col-1 mt-1">
                          <FontAwesomeIcon
                            icon={faChevronCircleRight}
                            className="blueChevron"
                          />
                        </div>
                        <div className="col-11">
                          <p>
                            Behöver inte ta hänsyn till ett snabbare ekonomiskt
                            flöde
                          </p>
                        </div>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-transparent border-0 py-0 py-0">
                      <Row>
                        <div className="col-1 mt-1">
                          <FontAwesomeIcon
                            icon={faChevronCircleRight}
                            className="blueChevron"
                          />
                        </div>
                        <div className="col-11">
                          <p>
                            Kan rådge under under året tack vare ständigt
                            uppdaterad information
                          </p>
                        </div>
                      </Row>
                    </ListGroup.Item>
                  </ListGroup>
                </Container>
              </Row>
              <Row className="my-5">
                <Container className="col-12 col-lg-3 mt-1">
                  <h6>Styrelsemedlemmar</h6>
                </Container>
                <Container className="col-12 col-lg-9">
                  <ListGroup className="listThingy">
                    <ListGroup.Item className="bg-transparent border-0 py-0 py-0">
                      <Row>
                        <div className="col-1 mt-1">
                          <FontAwesomeIcon
                            icon={faChevronCircleRight}
                            className="blueChevron"
                          />
                        </div>
                        <div className="col-11">
                          <p>Notifikationer i realtid /varningar </p>
                        </div>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-transparent border-0 py-0 py-0">
                      <Row>
                        <div className="col-1 mt-1">
                          <FontAwesomeIcon
                            icon={faChevronCircleRight}
                            className="blueChevron"
                          />
                        </div>
                        <div className="col-11">
                          <p>
                            Fatta juridiska beslut utan att behöva sätta sig in
                            i alla ekonomiska detaljer
                          </p>
                        </div>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-transparent border-0 py-0 py-0">
                      <Row>
                        <div className="col-1 mt-1">
                          <FontAwesomeIcon
                            icon={faChevronCircleRight}
                            className="blueChevron"
                          />
                        </div>
                        <div className="col-11">
                          <p>Informationsinhämtning från Xpektor i realtid</p>
                        </div>
                      </Row>
                    </ListGroup.Item>
                  </ListGroup>
                </Container>
              </Row>
              <Row className="my-5">
                <Container className="col-12 col-lg-3 mt-1">
                  <h6>Banker</h6>
                </Container>
                <Container className="col-12 col-lg-9">
                  <ListGroup className="listThingy">
                    <ListGroup.Item className="bg-transparent border-0 py-0 py-0">
                      <Row>
                        <div className="col-1 mt-1">
                          <FontAwesomeIcon
                            icon={faChevronCircleRight}
                            className="blueChevron"
                          />
                        </div>
                        <div className="col-11">
                          <p>
                            Får realtidsuppdaterad information om deras utlånad
                            kredit{' '}
                          </p>
                        </div>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-transparent border-0 py-0 py-0">
                      <Row>
                        <div className="col-1 mt-1">
                          <FontAwesomeIcon
                            icon={faChevronCircleRight}
                            className="blueChevron"
                          />
                        </div>
                        <div className="col-11">
                          <p>Möjlighet att reagera och agera i rätt tid</p>
                        </div>
                      </Row>
                    </ListGroup.Item>
                  </ListGroup>
                </Container>
              </Row>
              <Row className="my-5">
                <Container className="col-12 col-lg-3 mt-1">
                  <h6>Kreditinstitut</h6>
                </Container>
                <Container className="col-12 col-lg-9">
                  <ListGroup className="listThingy">
                    <ListGroup.Item className="bg-transparent border-0 py-0 py-0">
                      <Row>
                        <div className="col-1 mt-1">
                          <FontAwesomeIcon
                            icon={faChevronCircleRight}
                            className="blueChevron"
                          />
                        </div>
                        <div className="col-11">
                          <p>Kan genomföra kreditupplysning genom Xpektor </p>
                        </div>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-transparent border-0 py-0 py-0">
                      <Row>
                        <div className="col-1 mt-1">
                          <FontAwesomeIcon
                            icon={faChevronCircleRight}
                            className="blueChevron"
                          />
                        </div>
                        <div className="col-11">
                          <p>
                            Allt baserat på uppdaterat prediktivt resultat och
                            likviditet
                          </p>
                        </div>
                      </Row>
                    </ListGroup.Item>
                  </ListGroup>
                </Container>
              </Row>
            </Container>
          </div>
        </Row>
      </div>

      <header />

      <Row>
        <Container className="carouselContainer col-12 col-lg-5 justify-content-center mt-5 px-lg-5">
          <div className="d-none d-lg-block" style={{ height: 100 }} />
          <QuoteSwiper />
        </Container>

        <Container className="col-12 col-lg-7 text-center">
          <Image fluid src={PhonePic} />
        </Container>
      </Row>

      <header />
      <footer>
        <Contact />
      </footer>
    </div>
  );
}
