/* eslint-disable import/no-unresolved */
import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Autoplay, Navigation, Pagination, Scrollbar } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { Image } from 'react-bootstrap';
import Notis from '../../Images/LandingSpot/notiser i realtid.svg';
import PlugIn from '../../Images/LandingSpot/pluginSolution.svg';
import Overblick from '../../Images/LandingSpot/getOverview.svg';

export default function SwipeThing() {
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
      spaceBetween={5}
      slidesPerView={1.4}
      pagination={{ clickable: true }}
      centeredSlides
      centerInsufficientSlides
      loop
      loopFillGroupWithBlank
      autoplay={{
        delay: 3500,
        disableOnInteraction: true,
      }}
    >
      <SwiperSlide className="pb-5 swiper-slide-active">
        <a href="/product">
          <Image fluid src={Notis} />
        </a>
      </SwiperSlide>
      <SwiperSlide className="swiper-slide-next">
        <Link to="/product#overview">
          <Image fluid src={Overblick} />
        </Link>
      </SwiperSlide>
      <SwiperSlide className="swiper-slide-prev">
        <Link to="/product#start">
          <Image fluid src={PlugIn} />
        </Link>
      </SwiperSlide>
    </Swiper>
  );
}
