import './ServiceStatusTable.scss';
import { useState } from 'react';
import {
  faPlus,
  faExclamationCircle,
  faCircleCheck,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import XpektorButton from '../../../../Components/XpektorButton/XpektorButton';

/* eslint-disable react/prop-types */
export default function ServiceStatusTable({ children, onAddClick }) {
  return (
    <div className="service-status-message-table">
      <div className="title-container">
        <p className="table-title">Aktiva meddelanden</p>
        <XpektorButton content="Lägg till" icon={faPlus} onClick={onAddClick} />
      </div>
      <div className="title-underline" />
      <div className="items-container">
        {children.length !== 0 ? (
          children
        ) : (
          <p className="no-messages">Inga aktiva meddelanden</p>
        )}
      </div>
    </div>
  );
}

ServiceStatusTable.Item = function Item({ message, onDeleteClick }) {
  const [deleting, setDeleting] = useState(false);

  return (
    <div className="service-status-message-item">
      <div className="message-text-column">
        <div className="message-item-title-row">
          <p className="message-item-title">{message.title}</p>
          <FontAwesomeIcon
            className={`message-item-icon ${
              message.severity === 0
                ? 'ok'
                : message.severity === 1
                ? 'warning'
                : 'error'
            }`}
            icon={message.severity === 0 ? faCircleCheck : faExclamationCircle}
          />
        </div>
        <p className="message-item-text">{message.message}</p>
      </div>
      <div className="message-actions-column">
        <XpektorButton
          content="Ta bort"
          loading={deleting}
          onClick={() => {
            setDeleting(true);
            onDeleteClick(message.id);
          }}
        />
      </div>
    </div>
  );
};
