/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faArrowRight,
  faArrowUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';
import FortnoxLogo from '../../../Images/Logos/fortnox-svg.svg';
import VismaLogo from '../../../Images/Logos/Visma_spcs_logo.svg';
import CardButton from '../../../Components/CardButton/CardButton';

export default function AccountingAuthStep({
  completed,
  redirectToFortnox,
  redirectToVisma,
  error,
}) {
  return (
    <div className="auth-step">
      {completed ? (
        <div className="auth-step-completed-content">
          <FontAwesomeIcon
            className="auth-complete-icon"
            icon={faCircleCheck}
          />
          <p className="auth-complete-header">Bokföringssystemet är kopplat!</p>
        </div>
      ) : (
        <>
          <p className="auth-step-header">Koppla på bokföring</p>
          <p className="auth-step-description">
            Genom att koppla ditt bokföringssystem till Xpektor förenklas
            handläggningen. Xpektor hämtar automatiskt den information som
            behövs för att fatta beslut och du slipper själv skicka rapporter
            manuellt. Handläggaren får en klar bild över företagets ekonomi och
            processen kan snabbas på.
          </p>
          <button
            type="button"
            className="auth-step-help-link"
            onClick={() =>
              window.open('/tutorials', '_blank', 'noopener,noreferrer')
            }
          >
            <p>Läs mer om integrationslicenser</p>
            <FontAwesomeIcon
              className="auth-step-help-link-icon"
              icon={faArrowUpRightFromSquare}
            />
          </button>
          {error ? (
            <div className="auth-step-error">
              <p>
                Något gick fel och det gick inte att koppla bokföringssystemet.{' '}
                <a href="/tutorials" target="_blank" rel="noopener noreferrer">
                  Tryck här för hjälp.
                </a>
              </p>
            </div>
          ) : (
            ''
          )}
          <div className="integration-options-column">
            <CardButton
              textHeader="Fortnox"
              textContent="Kräver en integrationslicens i Fortnox"
              icon={faArrowRight}
              image={FortnoxLogo}
              onClick={redirectToFortnox}
            />
            <CardButton
              textHeader="Visma"
              textContent="Kräver en integrationslicens i Visma"
              icon={faArrowRight}
              image={VismaLogo}
              onClick={redirectToVisma}
            />
          </div>
        </>
      )}
    </div>
  );
}
