/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-undef */
import './ConnectAccountingModal.scss';
import { Button, Modal } from 'react-bootstrap';
import { useState } from 'react';
import {
  faArrowRight,
  faArrowUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AuthService from '../../../Services/AuthService';
import FortnoxLogo from '../../../Images/Logos/fortnox-svg.svg';
import VismaLogo from '../../../Images/Logos/Visma_spcs_logo.svg';
import CardButton from '../../../Components/CardButton/CardButton';

/* eslint-disable react/prop-types */
// eslint-disable-next-line react/prop-types
export default function ConnectAccountingModal({
  handleClose,
  action,
  client,
  authLevel,
}) {
  const [createClient] = useState({
    returnLocation: 2, // Create client
    targetId: null,
    action: 0, // Create client
  });

  const [editClient] = useState({
    returnLocation: 1, // Update client
    targetId: client.id,
    action: 1, // Update client
  });

  const redirectToFortnox = async () => {
    if (action === 'createClient') {
      await AuthService.getAuthUrlFortnox(
        createClient.returnLocation,
        createClient.targetId,
        createClient.action
      ).then((response) => {
        if (response) {
          window.location.replace(response);
        }
      });
    }
    if (action === 'updateClient') {
      await AuthService.getAuthUrlFortnox(
        editClient.returnLocation,
        editClient.targetId,
        editClient.action
      ).then((response) => {
        if (response) {
          window.location.replace(response);
        }
      });
    }
  };
  const redirectToVisma = async () => {
    if (action === 'createClient') {
      await AuthService.getAuthUrlVisma(
        createClient.returnLocation,
        createClient.targetId,
        createClient.action
      ).then((response) => {
        if (response) {
          window.location.replace(response);
        }
      });
    }
    if (action === 'updateClient') {
      await AuthService.getAuthUrlVisma(
        editClient.returnLocation,
        editClient.targetId,
        editClient.action
      ).then((response) => {
        if (response) {
          window.location.replace(response);
        }
      });
    }
  };
  return (
    <Modal
      show
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="connect-accounting-modal"
    >
      <Modal.Header className="border-0">
        <Button className="btn-close" bsPrefix="button" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body className="border-0">
        <p className="connect-title">Koppla klient med ett bokföringssystem.</p>
        <p className="connect-description">
          Här kan du direkt koppla en klient med ett bokföringssystem om du har
          åtkomst till företaget.
        </p>
        <button
          type="button"
          className="auth-step-help-link"
          onClick={() =>
            window.open('/tutorials', '_blank', 'noopener,noreferrer')
          }
        >
          <p>Läs mer om integrationslicenser</p>
          <FontAwesomeIcon
            className="auth-step-help-link-icon"
            icon={faArrowUpRightFromSquare}
          />
        </button>
        <div className="integration-options">
          <CardButton
            textHeader="Fortnox"
            textContent="Kräver en integrationslicens i Fortnox"
            icon={faArrowRight}
            image={FortnoxLogo}
            onClick={redirectToFortnox}
            styleClass="integration-partner-button-content"
          />
          <CardButton
            textHeader="Visma"
            textContent="Kräver en integrationslicens i Visma"
            icon={faArrowRight}
            image={VismaLogo}
            onClick={redirectToVisma}
            styleClass="integration-partner-button-content"
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
