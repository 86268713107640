import './ServiceStatusDisplay.scss';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons';

/* eslint-disable react/prop-types */
export default function ServiceStatusDisplay({ alerts }) {
  const [open, setOpen] = useState(false);
  const [severityClass, setSeverityClass] = useState('error');

  // Find the highest severity among the messages
  useEffect(() => {
    if (alerts !== null && alerts.length > 0) {
      let highestSeverity = 0;
      for (let i = 0; i < alerts.length; i += 1) {
        if (alerts[i].severity > highestSeverity) {
          highestSeverity = alerts[i].severity;
        }
      }
      setSeverityClass(
        highestSeverity === 0
          ? 'ok'
          : highestSeverity === 1
          ? 'warning'
          : 'error'
      );
    }
  }, [alerts]);

  return (
    <div
      className={`service-status-display ${severityClass}`}
      data-tooltip-id="service-status-tooltip"
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <FontAwesomeIcon
        className={`service-status-icon ${severityClass}`}
        icon={severityClass === 'ok' ? faCircleCheck : faCircleExclamation}
      />
      <p className={`service-status-title ${severityClass}`}>Driftstörningar</p>
      {alerts ? (
        <div
          className={`service-status-tooltip ${
            open ? 'service-status-tooltip-open' : ''
          }`}
        >
          {alerts.map((alert) => {
            return (
              <div className="service-status-tooltip-message" key={alert.id}>
                <div className="tooltip-message-title-row">
                  <p className="tooltip-message-title">{alert.title}</p>
                  <FontAwesomeIcon
                    icon={
                      alert.severity === 0 ? faCircleCheck : faCircleExclamation
                    }
                    className={`tooltip-message-icon ${
                      alert.severity === 0
                        ? 'ok'
                        : alert.severity === 1
                        ? 'warning'
                        : 'error'
                    }`}
                  />
                </div>
                <p className="tooltip-message-text">{alert.message}</p>
              </div>
            );
          })}
        </div>
      ) : (
        ''
      )}
    </div>
  );
}
