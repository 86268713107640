import './Tutorials.scss';
import { useState } from 'react';
import FortnoxLogo from '../../../Images/Logos/fortnox-svg.svg';
import VismaLogo from '../../../Images/Logos/Visma_spcs_logo.svg';
import FortnoxImg1 from '../../../Images/Tutorials/Fortnox/License/Fortnox_integration_1.png';
import FortnoxImg2 from '../../../Images/Tutorials/Fortnox/License/Fortnox_integration_2.png';
import FortnoxImg3 from '../../../Images/Tutorials/Fortnox/License/Fortnox_integration_3.png';
import FortnoxImg4 from '../../../Images/Tutorials/Fortnox/License/Fortnox_integration_4.png';
import VismaLic1 from '../../../Images/Tutorials/Visma/License/Visma_integration_1.png';
import VismaLic2 from '../../../Images/Tutorials/Visma/License/Visma_integration_2.png';
import VismaLic3 from '../../../Images/Tutorials/Visma/License/Visma_integration_3.png';
import VismaLic4 from '../../../Images/Tutorials/Visma/License/Visma_integration_4.png';
import VismaLic5 from '../../../Images/Tutorials/Visma/License/Visma_integration_5.png';
import VismaCom1 from '../../../Images/Tutorials/Visma/StandardCompany/Visma_standardföretag_1.png';
import VismaCom2 from '../../../Images/Tutorials/Visma/StandardCompany/Visma_standardföretag_2.png';
import VismaCom3 from '../../../Images/Tutorials/Visma/StandardCompany/Visma_standardföretag_3.png';
import VismaCom4 from '../../../Images/Tutorials/Visma/StandardCompany/Visma_standardföretag_4.png';
import VismaCom5 from '../../../Images/Tutorials/Visma/StandardCompany/Visma_standardföretag_5.png';

/* eslint-disable react/prop-types */
function AccountingSystemButton({ selected, onClick, logo }) {
  return (
    <button
      className={`tutorial-nav-button ${selected ? 'selected-nav-button' : ''}`}
      type="button"
      onClick={onClick}
    >
      <img className="partner-logo" src={logo} alt="LOGO" />
    </button>
  );
}

function TutorialButton({ title, onClick, selected }) {
  return (
    <button
      className={`tutorial-button ${
        selected ? 'selected-tutorial-button' : ''
      }`}
      type="button"
      onClick={onClick}
    >
      {title}
    </button>
  );
}

export default function Tutorials() {
  const [accountingSystem, setAccountingSystem] = useState('Fortnox');
  const [selectedTutorial, setSelectedTutorial] = useState(0);

  const fortnoxTutorials = [
    {
      id: 0,
      tutorialContent: [
        { img: FortnoxImg1, index: 0 },
        { img: FortnoxImg2, index: 1 },
        { img: FortnoxImg3, index: 2 },
        { img: FortnoxImg4, index: 3 },
      ],
    },
  ];

  const vismaTutorials = [
    {
      id: 0,
      tutorialContent: [
        { img: VismaLic1, index: 0 },
        { img: VismaLic2, index: 1 },
        { img: VismaLic3, index: 2 },
        { img: VismaLic4, index: 3 },
        { img: VismaLic5, index: 4 },
      ],
    },
    {
      id: 1,
      tutorialContent: [
        { img: VismaCom1, index: 0 },
        { img: VismaCom2, index: 1 },
        { img: VismaCom3, index: 2 },
        { img: VismaCom4, index: 3 },
        { img: VismaCom5, index: 4 },
      ],
    },
  ];

  const selectedImages =
    accountingSystem === 'Fortnox'
      ? fortnoxTutorials[selectedTutorial].tutorialContent
      : accountingSystem === 'Visma'
      ? vismaTutorials[selectedTutorial].tutorialContent
      : [];

  return (
    <div className="tutorials-wrapper">
      <div className="tutorial-header">
        <p className="tutorial-title">Instruktioner för bokföringssystem</p>
        <div className="tutorial-accounting-nav">
          <AccountingSystemButton
            logo={FortnoxLogo}
            selected={accountingSystem === 'Fortnox'}
            onClick={() => {
              setSelectedTutorial(0);
              setAccountingSystem('Fortnox');
            }}
          />
          <AccountingSystemButton
            logo={VismaLogo}
            selected={accountingSystem === 'Visma'}
            onClick={() => {
              setSelectedTutorial(0);
              setAccountingSystem('Visma');
            }}
          />
        </div>
        <div className="tutorial-selection-nav">
          {accountingSystem === 'Fortnox' ? (
            <TutorialButton
              title="Integrationslicens"
              onClick={() => {
                setSelectedTutorial(0);
              }}
              selected={selectedTutorial === 0}
            />
          ) : accountingSystem === 'Visma' ? (
            <>
              <TutorialButton
                title="Integrationslicens"
                onClick={() => {
                  setSelectedTutorial(0);
                }}
                selected={selectedTutorial === 0}
              />
              <TutorialButton
                title="Standardföretag"
                onClick={() => {
                  setSelectedTutorial(1);
                }}
                selected={selectedTutorial === 1}
              />
            </>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="tutorial-content">
        {selectedImages.map((data) => {
          return (
            <img
              className="tutorial-image"
              src={data.img}
              alt="TutorialImage"
              key={data.index}
            />
          );
        })}
      </div>
    </div>
  );
}
