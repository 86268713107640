import './Footer.scss';
import HoverableText from '../../../Components/HoverableText/HoverableText';

export default function Footer() {
  const tooltipContent = () => {
    return (
      <div className="contact-container">
        <div className="column">
          <p>Hanna Gårdmark</p>
          <p>CEO</p>
          <p>+46 720 142 474</p>
          <p>hanna@wecoorp.com</p>
        </div>
        <div className="column">
          <p>Daniel Eremar</p>
          <p>Head of sales</p>
          <p>+46 769 424 744</p>
          <p>daniel@wecoorp.com</p>
        </div>
      </div>
    );
  };
  return (
    <div className="footer-wrapper">
      <div className="footer-content">
        <div className="link">
          <HoverableText text="Kontakta oss" info={tooltipContent()} />
        </div>
        <div className="trademark">
          <p>© 2024 All rights reserved</p>
        </div>
      </div>
    </div>
  );
}
