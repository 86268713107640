/* eslint-disable react/prop-types */
import './Report.scss';
import Moment from 'moment';
import NumberFormat from 'react-number-format';

export default function Report({
  title,
  client,
  fiscalYear,
  period,
  dateCreated,
  children,
}) {
  return (
    <div className="report-wrapper">
      <div className="report-header">
        <div className="report-client-column">
          <p>{client.name}</p>
          <p>{client.organizationNumber}</p>
          <p>{`Räkenskapsår: ${fiscalYear ?? ''}`}</p>
          <p>{`Period: ${period ?? ''}`}</p>
        </div>
        <div className="report-title-column">
          <p>{title}</p>
        </div>
        <div className="report-document-column">
          <p>{`Konton hämtade: ${
            Moment(dateCreated).format('YYYY-MM-DD HH:mm') ?? ''
          }`}</p>
        </div>
      </div>
      <div className="report-content">{children}</div>
    </div>
  );
}

Report.TableRow = function TableRow({
  number,
  title,
  values,
  bold,
  marginBottom,
  type,
}) {
  return (
    <div
      className={`report-table-row ${marginBottom ? 'tr-bottom-space' : ''}`}
    >
      <div className="title-section">
        {number && <p className="report-account-number">{number}</p>}
        <p className={bold ? 'report-bold' : ''}>{title}</p>
      </div>
      <div className="values-section">
        {values &&
          values.map((value, index) => {
            return (
              <p
                className={bold ? 'report-bold' : ''}
                key={String(Math.random()) + index.toString()}
              >
                {type === 'text' ? (
                  value
                ) : (
                  <NumberFormat
                    value={value}
                    displayType="text"
                    decimalScale={2}
                    thousandSeparator={' '}
                    key={String(Math.random()) + index.toString()}
                  />
                )}
              </p>
            );
          })}
      </div>
    </div>
  );
};

Report.Divider = function Divider() {
  return <div className="report-divider" />;
};

Report.ListSection = function ListSection({ children }) {
  return <div className="report-list-section">{children}</div>;
};

Report.ListItem = function ListItem() {
  return '';
};
