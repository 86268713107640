import { Button, Modal } from 'react-bootstrap';
import { useEffect, useState, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import XpektorButton from '../../../../../Components/XpektorButton/XpektorButton';
import CustomerService from '../../../../../Services/CustomerService';
import UserClientAccessService from '../../../../../Services/UserClientAccessService';
import Scroll from '../../../../../Components/Common/Scroll';
import './HandleUserClientAccess.scss';

/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
// eslint-disable-next-line react/prop-types
function HandleUserClientAccess({ handleClose, customerId, client }) {
  const [users, setUsers] = useState([]);
  const [currentUsers, setCurrentUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [scrollUpVisable, setScrollUpVisable] = useState(false);
  const [scrollDownVisable, setScrollDownVisable] = useState(true);

  const listRef = useRef(null);

  useEffect(() => {
    const getUsers = async () => {
      CustomerService.getUsers(customerId)
        .then((response) => {
          setUsers(response.data);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            setUsers([]);
            alert('Hittar inga användare under kund');
          }
          if (error.response.status === 500) {
            alert('Något gick fel, försök igen senare');
          }
        });
    };

    const GetUserClientAccess = async () => {
      UserClientAccessService.GetUserClientAccessOnClientId(client.id)
        .then((response) => {
          const temp = response.data;
          temp.map((item) => {
            setCurrentUsers((prevState) => [...prevState, item.userId]);
            return setSelectedUsers((prevState) => [...prevState, item.userId]);
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            setSelectedUsers([]);
          }
          if (error.response.status === 500) {
            alert('Något gick fel, försök igen senare');
          }
        });
    };
    getUsers();
    GetUserClientAccess();
  }, []);

  const handleChange = (e) => {
    const { checked, value } = e.currentTarget;
    setSelectedUsers((prevState) =>
      checked ? [...prevState, value] : prevState.filter((val) => val !== value)
    );
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    const top = e.currentTarget.scrollTop === 0;
    setScrollUpVisable(!top);
    setScrollDownVisable(!bottom);
  };

  const handleSubmit = () => {
    selectedUsers.forEach((userId) => {
      if (!currentUsers.includes(userId)) {
        UserClientAccessService.GetOrCreateUserClientAccess(
          userId,
          client.id
        ).then((r) => console.log(r));
      }
    });
    currentUsers.forEach((userId) => {
      if (!selectedUsers.includes(userId)) {
        UserClientAccessService.DeleteUserClientAccess(userId, client.id).then(
          (r) => console.log(r)
        );
      }
    });
    setUsers([]);
    setCurrentUsers([]);
    setSelectedUsers([]);
    handleClose();
  };

  return (
    <Modal
      show
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="border-0"
    >
      <Modal.Header className="border-0">
        <Modal.Title>Behörighet</Modal.Title>
        <Button className="btn-close" bsPrefix="button" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body className="border-0">
        <Form>
          <Form.Group>
            <Form.Label>{`Ändra vem som ser och har åtkomst till "${client.name}":`}</Form.Label>
            <div
              style={{
                width: '100%',
                maxWidth: 360,
                border: '1px solid #6b6b6b',
              }}
            >
              <Scroll
                listRef={listRef}
                scrollUpVisable={scrollUpVisable}
                scrollDownVisable={scrollDownVisable}
                scrollHeight="140"
              >
                <List
                  onScroll={handleScroll}
                  className="userList"
                  ref={listRef}
                  sx={{
                    width: '100%',
                    overflow: 'auto',
                    maxHeight: 280,
                    padding: 0,
                  }}
                >
                  {users.map((user, i) => (
                    <ListItem className="form-check" key={user.id}>
                      <ListItemButton
                        className="w-100"
                        value={user?.id}
                        onClick={handleChange}
                        dense
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            type="checkbox"
                            name={i.toString()}
                            value={user.id}
                            onChange={handleChange}
                            checked={selectedUsers.includes(user.id)}
                          />
                          <ListItemText className="pt-2" primary={user.name} />
                        </ListItemIcon>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Scroll>
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <XpektorButton content="Spara" onClick={handleSubmit} />
      </Modal.Footer>
    </Modal>
  );
}

export default HandleUserClientAccess;
