import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import './Scroll.scss';
/* eslint-disable react/prop-types */

function ScrollButton(props) {
  const { icon, onClick, visible } = props;
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 32,
      }}
    >
      <FontAwesomeIcon
        className="scrollIcon"
        icon={icon}
        onClick={onClick}
        size="2x"
        style={{
          opacity: visible ? 1 : 0,
        }}
      />
    </div>
  );
}

export default function Scroll(props) {
  const {
    children,
    listRef,
    scrollDownVisable,
    scrollUpVisable,
    scrollHeight,
  } = props;

  const handleClickScroll = (scroll) => {
    listRef?.current.scrollBy({
      top: scroll,
      behavior: 'smooth',
    });
  };

  return (
    <div>
      <ScrollButton
        icon={faAngleUp}
        onClick={() => handleClickScroll(-scrollHeight)}
        visible={scrollUpVisable}
      />
      {children}
      <ScrollButton
        icon={faAngleDown}
        onClick={() => handleClickScroll(scrollHeight)}
        visible={scrollDownVisable}
      />
    </div>
  );
}
