/* eslint-disable react/prop-types */
import './ExternalAuthComponents.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

export function AuthStepDisplay({ children, step }) {
  return (
    step !== 0 && (
      <div className="auth-step-display">
        <div className="auth-step-icon-row">{children}</div>
      </div>
    )
  );
}

export function AuthStepIcon({ connected, selected, text }) {
  return (
    <div
      className={`auth-step-icon-circle ${
        connected ? 'auth-complete' : 'auth-incomplete'
      } ${selected ? 'auth-step-selected' : ''}`}
    >
      {connected ? (
        <FontAwesomeIcon className="auth-step-icon" icon={faCircleCheck} />
      ) : (
        <div className="auth-step-icon-dot" />
      )}
      <p className="auth-step-icon-text">{text}</p>
    </div>
  );
}

export function AuthStepIconLine({ active }) {
  return (
    <div
      className={`auth-step-line ${active ? 'auth-step-line-active' : ''}`}
    />
  );
}
