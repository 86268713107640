/* eslint-disable react/prop-types */
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import './Question.scss';

export default function Question({ question }) {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="faq-question-wrapper">
      <button
        className="faq-title-container"
        type="button"
        onClick={() => setExpanded(!expanded)}
      >
        <p>{question.title}</p>
        <FontAwesomeIcon icon={expanded ? faChevronDown : faChevronRight} />
      </button>
      <div className={`faq-answer-container ${!expanded && 'answer-hidden'}`}>
        <p>{question.answer}</p>
      </div>
    </div>
  );
}
