import React from 'react';
import { Container, Image, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleDown,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import LandingNavigation from '../LandingNavigation/LandingNavigation';
import Contact from '../../../Components/Contact/Contact';
import './Product.scss';
import Phone1 from '../../../Images/Product/iPhone 12 Graphite Pro Top View Mockup (1).svg';
import Phone2 from '../../../Images/Product/iPhone 12 Graphite Pro Top View Mockup.svg';
import MacImage1 from '../../../Images/Product/iMac Pro Left Side View Mockup.png';

export default function Product() {
  return (
    <div className="w-100">
      <LandingNavigation />
      <div className="mb-5">
        <Row className="mt-lg-5">
          <Container className="phoneGuy col-12 col-lg-7 pt-lg-5 mt-lg-2">
            <h2 className="text-white text-end">Få notiser i realtid</h2>
          </Container>
          <Container className="col-12 col-lg-5">
            <Row className="px-5 px-lg-0">
              <Container className="phone1 col-6">
                <Image fluid src={Phone1} alt="notifications-image" />
              </Container>
              <Container className="phone2 col-6">
                <Image fluid src={Phone2} alt="notifications-image" />
              </Container>
              <span className="phoneText text-start">
                <Row>
                  <div className="col-11">
                    <p>
                      Få notiser hur ni vill och när ni vill. Dagligen, veckovis
                      eller månadsvis? Vi skräddarsyr efter era önskemål.
                    </p>
                  </div>
                </Row>
              </span>
            </Row>
          </Container>
        </Row>

        <Row className="firstRow d-flex">
          <header />
          <div
            className="rowTitle col-12 col-lg-5 pt-5 ps-5 pb-lg-5 pt-lg-5"
            id="start"
          >
            <h2 className="ms-lg-5">Utnyttja Xpektors kraft genom att:</h2>
          </div>
          <div className="textContainer col-12 col-lg-5">
            <Container className="justify-content-start ms-lg-4 px-5">
              <ul>
                <li>
                  Ta beslut baserat på prediktiva resultat och inte historisk
                  data
                </li>
                <li>Få en årsredovisning om dagen</li>
                <li>Korta ner handläggningstider och effektivera processer</li>
                <li>Upptäcka och stoppa bedrägerier</li>
                <li>Bevaka och prioritera bland kunder</li>
                <li>Tillgång till unik och heltäckande score</li>
                <li>Access via enkel plug-in eller via Xpektor</li>
                <li>
                  Värna om integritet genom att inte skrapa bokföring på
                  transaktionsnivå
                </li>
                <li>Jobba i en användarvänlig och lättnavigeradmiljö</li>
              </ul>
            </Container>
          </div>
          <div className="imgContainer col-12 col-lg-7">
            <div className="text-end">
              <Image fluid src={MacImage1} alt="computer-image" />
            </div>
          </div>
        </Row>

        <header />
        <Row className="secondRow">
          <div className="col-12 col-lg-8" id="overview">
            <Row>
              <div className="col-12 col-lg-3 text-start text-lg-end pt-3">
                <h5 className="text-uppercase text ps-5 ps-lg-0">Dashboard</h5>
              </div>
              <div className="col-12 col-lg-7 pe-lg-5">
                <p className="px-5 px-lg-0">
                  Via vår dashbard får ni som användare en tydlig överblick av
                  de viktigaste modulerna och tillhörande nyckeltal. Klicka
                  enkelt vidare för att få en granulerad vy och utforska
                  respektive modul mer i detalj
                </p>
              </div>
              <div className="col-2 d-none d-lg-block" />
            </Row>

            <Container className="computerOne my-4" />
          </div>
          <div className="col-12 col-lg-4 align-self-center">
            <Container className="iconsContainer mt-5 mt-lg-5 pt-lg-5">
              <Row>
                <div className="col-2 mt-3 mt-md-2 text-end text-lg-start">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="greenIcon"
                  />
                </div>
                <div className="col-10">
                  <p className="greenIcon">Inga avvikelser.</p>
                </div>
              </Row>
              <Row>
                <div className="col-2 mt-3 mt-md-2 text-end text-lg-start">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="yellowIcon"
                  />
                </div>
                <div className="col-10">
                  <p className="yellowIcon">Avvikelse upptäckt.</p>
                </div>
              </Row>
              <Row>
                <div className="col-2 mt-3 mt-md-2 text-end text-lg-start">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="redIcon"
                  />
                </div>
                <div className="col-10">
                  <p className="redIcon">Konstaterad avvikelse.</p>
                </div>
              </Row>
            </Container>
          </div>
          <div className="text-center mt-5 pt-5 mb-1 pb-1">
            <p className="ctaText text-uppercase">
              LÄS MER OM MODULERNA HÄR{' '}
              <FontAwesomeIcon className="ms-2" icon={faAngleDoubleDown} />
            </p>
          </div>
        </Row>
        <header />
        <Container className="py-3">
          <h1>6 st Moduler och unik riskanalys</h1>
          <p className="no-img">
            Xpektors kärna kretsar kring sex moduler sammansatta så att ni får
            ett komplett beslutsunderlag. Varje modul kan analyseras i detalj
            och med hjälp av notifikationer visar Xpektor det ni bör vara
            observanta på. Helheten sätts samman och presenteras i vår
            Riskanalys (som kommer snart).{' '}
          </p>

          <Row className="thirdRow">
            <div className="col-12 col-lg-10">
              <Row className="ps-5">
                <div className="col-12 col-lg-3 py-3 no-img">
                  <h5 className="text-uppercase">Eget kapital</h5>
                </div>
                <div className="col-12 col-lg-9 no-img">
                  <p className="px-2 px-lg-0">
                    Modulen ger möjlighet att fatta beslut baserat på ett
                    prediktivt resultat. Xpektor räknar in allt i realtid,
                    oavsett om det är bokfört eller inte. I samma vy visas också
                    det egna kapitalet i förhållande till resultatet. Xpektor
                    varnar om det egna kapitalet är på väg att förbrukas,
                    bokfört eller inte.
                  </p>
                </div>
                <div className="col-lg-2 d-none d-lg-block" />
              </Row>
            </div>
          </Row>

          <Row className="thirdRow">
            <div className="col-12 col-lg-10">
              <Row className="ps-5">
                <div className="col-12 col-lg-3 py-3 no-img">
                  <h5 className="text-uppercase">Likviditet</h5>
                </div>
                <div className="col-12 col-lg-9 no-img">
                  <p className="px-2 px-lg-0">
                    Följ bolagets likviditet i realtid och framtida
                    likviditetsflöden baserat på både bokfört och ej bokfört
                    data. Kommande inbetalningar och utbetalningar sammanställs
                    och redovisar det prediktiva kassaflödet. Eftersom Xpektor
                    är uppkopplat mot bank och skattekonto blir ni notifierade
                    om exempelvis kassan är på väg på att ta slut eller om
                    skattekontot är negativt.
                  </p>
                </div>
                <div className="col-lg-2 d-none d-lg-block" />
              </Row>
            </div>
          </Row>

          <Row className="thirdRow">
            <div className="col-12 col-lg-10">
              <Row className="ps-5">
                <div className="col-12 col-lg-3 py-3 no-img">
                  <h5 className="text-uppercase">Förvaltning</h5>
                </div>
                <div className="col-12 col-lg-9 no-img">
                  <p className="px-2 px-lg-0">
                    Se nyckeltal i realtid och ha full koll på om bolaget har
                    väsentliga ökningar/minskningar i
                    omsättning/kostnader/skulder/tillgångar.
                  </p>
                </div>
                <div className="col-lg-2 d-none d-lg-block" />
              </Row>
            </div>
          </Row>

          <Row className="thirdRow">
            <div className="col-12 col-lg-10">
              <Row className="ps-5">
                <div className="col-12 col-lg-3 py-3 no-img">
                  <h5 className="text-uppercase">Regulatoriskt</h5>
                </div>
                <div className="col-12 col-lg-9 no-img">
                  <p className="px-2 px-lg-0">
                    Vilka sitter i bolagets styrelse? Sedan hur länge? Betalas
                    skatter? Är företaget registrerad i arbetsgivarregistret och
                    för moms och F-Skatt? Ja detta och mycket mer visar den
                    regulatoriska modulen.
                  </p>
                </div>
                <div className="col-lg-2 d-none d-lg-block" />
              </Row>
            </div>
          </Row>

          <Row className="thirdRow">
            <div className="col-12 col-lg-10">
              <Row className="ps-5">
                <div className="col-12 col-lg-3 py-3 no-img">
                  <h5 className="text-uppercase">Kontoanalys</h5>
                </div>
                <div className="col-12 col-lg-9 no-img">
                  <p className="px-2 px-lg-0">
                    Filtrera, sortera, kategorisera och analysera hur företagets
                    pengar spenderas. Se om det finns betalningar till
                    kronofogden, inkasso, högrisk länder och få tillgång till en
                    rad andra funktioner
                  </p>
                </div>
                <div className="col-lg-2 d-none d-lg-block" />
              </Row>
            </div>
          </Row>
          <Row className="thirdRow">
            <div className="col-12 col-lg-10">
              <Row className="ps-5">
                <div className="col-12 col-lg-3 py-3 no-img">
                  <h5 className="text-uppercase">Rapporter</h5>
                </div>
                <div className="col-12 col-lg-9 no-img">
                  <p className="px-2 px-lg-0">
                    Xpektor skapar en årsredovisning om dagen och vi
                    autogenererar balans och resultatrapport. Känn er trygga med
                    helt färska siffror och slipp begära in kompletteringar.
                    Allt detta utan att skrapa bokföring på transaktionsnivå.
                  </p>
                </div>
                <div className="col-lg-2 d-none d-lg-block" />
              </Row>
            </div>
          </Row>
        </Container>

        <header />
      </div>
      <footer className="col-12">
        <Contact />
      </footer>
    </div>
  );
}
