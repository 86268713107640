import './BalanceBar.scss';

/* eslint-disable react/prop-types */
export default function BalanceBar({
  leftPercentage,
  rightPercentage,
  leftSum,
  rightSum,
  sumSign,
  leftColor,
  rightColor,
  leftHeader,
  rightHeader,
  leftData,
  rightData,
}) {
  return (
    <div className="balance-bar">
      <div className="title-row">
        <p className="title">{leftHeader}</p>
        <p className="title">{rightHeader}</p>
      </div>
      <div className="bar-content">
        <div
          className="fill-bar"
          style={{
            width: `${leftPercentage ?? 50}%`,
            boxShadow: `inset 1px 1px 12px ${leftColor}, inset -1px -1px 12px ${leftColor}`,
          }}
        >
          {leftPercentage && leftPercentage > 15 ? (
            <p className="percentage-text">{`${leftPercentage.toFixed(
              1
            )} %`}</p>
          ) : (
            ''
          )}
        </div>
        <div
          className="fill-bar"
          style={{
            width: `${rightPercentage ?? 50}%`,
            boxShadow: `inset 1px 1px 12px ${rightColor}, inset -1px -1px 12px ${rightColor}`,
          }}
        >
          {rightPercentage && rightPercentage > 15 ? (
            <p className="percentage-text">{`${rightPercentage.toFixed(
              1
            )} %`}</p>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="details-content">
        <div className="details-column">
          {leftData ? (
            Object.entries(leftData).map(([key, value]) => {
              return (
                <p key={`acc-insum-${key}`}>
                  {`${Number(value.toFixed(0))
                    .toLocaleString()
                    .replaceAll(',', ' ')} ${key}`}
                </p>
              );
            })
          ) : (
            <p>-</p>
          )}
          {leftSum ? (
            <p className="details-sum">{`(${Number(leftSum.toFixed(0))
              .toLocaleString()
              .replaceAll(',', ' ')} ${sumSign})`}</p>
          ) : (
            ''
          )}
        </div>
        <div className="details-column">
          {rightData ? (
            Object.entries(rightData).map(([key, value]) => {
              return (
                <p key={`acc-outsum-${key}`}>
                  {`${Number(value.toFixed(0))
                    .toLocaleString()
                    .replaceAll(',', ' ')} ${key}`}
                </p>
              );
            })
          ) : (
            <p>-</p>
          )}
          {rightSum ? (
            <p className="details-sum">{`(${Number(rightSum.toFixed(0))
              .toLocaleString()
              .replaceAll(',', ' ')} ${sumSign})`}</p>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
}
